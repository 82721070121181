import React, { FC, useEffect, useState } from 'react';
import { VictoryAxis, VictoryChart } from 'victory';
import Polygon from '../../../../../../components/Graphs/Polygon/Polygon';
import scenariosColors from '../../../../../../theming/scenariosColors';
import { ModelRun } from '../../../../../../types/graphql/RunHistory';
import { handlePeakYearData } from '../../helpers';
import TemperatureGraphProps from './TemperatureGraphProps';
import { PeakYearDataSet } from './types';

const TemperatureGraph: FC<TemperatureGraphProps> = ({
	probabilisticActiveRuns,
}) => {
	const [chartDataset, setChartDataset] = useState<PeakYearDataSet[]>([]);

	useEffect(() => {
		if (probabilisticActiveRuns) {
			const newArray: PeakYearDataSet[] = [];
			probabilisticActiveRuns.forEach((modelRun: ModelRun) => {
				const peakYearData = handlePeakYearData(modelRun.data.peakYears);

				const fixedPeakYearData = peakYearData.map((peakYear) => {
					if (peakYear[0].x === peakYear[peakYear.length - 1].x) {
						return [
							...peakYear.splice(0, peakYear.length - 2),
							{
								x: peakYear[peakYear.length - 2].x + 1,
								y: peakYear[peakYear.length - 2].y,
							},
							{
								x: peakYear[peakYear.length - 1].x + 1,
								y: peakYear[peakYear.length - 1].y,
							},
						];
					}
					return peakYear;
				});

				newArray.push({
					lightColor: scenariosColors[`${modelRun.emissionScenario.code}-fill`],
					darkColor: scenariosColors[modelRun.emissionScenario.code],
					data: fixedPeakYearData,
				});
			});
			setChartDataset(newArray);
		}
	}, [probabilisticActiveRuns]);

	return (
		<VictoryChart
			height={300}
			width={800}
			domain={{ x: [2000, 2150], y: [0, 10] }}
		>
			<VictoryAxis
				tickFormat={(t) => `${Math.round(t)}`}
				tickCount={15}
				style={{
					tickLabels: {
						fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						fontSize: '8px',
						color: '#ADADAD',
					},
					grid: {
						stroke: '#eee',
					},
				}}
				crossAxis
			/>
			<VictoryAxis
				tickFormat={(t) => `${t}°`}
				tickCount={20}
				style={{
					tickLabels: {
						fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						fontSize: '8px',
						color: '#ADADAD',
					},
					grid: {
						stroke: '#eee',
					},
				}}
				dependentAxis
			/>
			{chartDataset.map((el: PeakYearDataSet) => {
				return [
					<Polygon
						data={el.data[0]}
						style={{
							fill: el.lightColor,
							opacity: 0.5,
						}}
					/>,
					<Polygon
						data={el.data[1]}
						style={{
							fill: el.darkColor,
							opacity: 1,
						}}
					/>,
					<Polygon
						data={el.data[2]}
						style={{
							fill: el.darkColor,
							opacity: 0.5,
						}}
					/>,
					<Polygon
						data={el.data[3]}
						style={{
							fill: el.lightColor,
							opacity: 0.5,
						}}
					/>,
				];
			})}
		</VictoryChart>
	);
};

export default TemperatureGraph;
