import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@material-ui/core';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import InputRange from '../../../../components/Form/InputRange/InputRange';
import useStyles from './PanelUncertaintyStyles';
// import DropdownOptions from '../../../../components/Form/DropdownOptions/DropdownOptions';
// import { regions } from '../../../../config/constants/regions';
import PanelTemplateProps from '../../../../components/Page/Panel/types';
import { ModelRun } from '../../../../types/graphql/RunHistory';
import { DataSetUncertainty } from '../../../../components/Graphs/types';
import scenariosColors from '../../../../theming/scenariosColors';
import EmptyStatePanel from '../../../../components/Page/EmptyStatePanel/EmptyStatePanel';
import { PROBABILISTIC_TYPE } from '../../../../config/constants/modelTypes';

const PanelUncertainty: FC<PanelTemplateProps> = ({
	setWindowCenter,
	windowCenter,
	activeModelRuns,
}) => {
	const [densityData, setDensityData] = useState<DataSetUncertainty[]>([]);
	const [showEmptyState, setShowEmptyState] = useState<boolean>(true);
	const { t } = useTranslation();

	const handleArrayData = async (array: ModelRun[]) => {
		setDensityData([]);

		const newArray: DataSetUncertainty[] = [];

		await array.forEach((modelRun: ModelRun) => {
			newArray.push({
				uuid: modelRun.uuid,
				label: modelRun.name,
				data: modelRun.data.density,
				backgroundColor:
					scenariosColors[`${modelRun.emissionScenario.code}-fill`],
				borderColor: scenariosColors[modelRun.emissionScenario.code],
				pointRadius: 1,
				lineTension: 0.2,
			});
		});
		setDensityData(newArray);
	};

	useEffect(() => {
		if (activeModelRuns) {
			const probabilisticRuns = activeModelRuns?.filter(
				(modelRun: ModelRun) => modelRun.modelRunType === PROBABILISTIC_TYPE
			);
			if (probabilisticRuns.length === 0) {
				setShowEmptyState(true);
			} else {
				setShowEmptyState(false);
				handleArrayData(probabilisticRuns);
			}
		}
	}, [activeModelRuns]);

	const classes = useStyles();

	if (showEmptyState) {
		return <EmptyStatePanel panel="uncertainty" classes={classes} />;
	}

	const datasetKeyProvider = () => {
		return uuidv4();
	};
	return (
		<>
			<Grid item xs={12} sm={12} md={4}>
				<PanelHeader isBright>{t('domain:uncertainty.title')}</PanelHeader>
				<Paragraph secondary>{t('domain:uncertainty.description')}</Paragraph>

				<div className={classes.dropdownContainer}>
					<Typography variant="h3" className={classes.titles}>
						{t('domain:uncertainty.adjust-window')}
					</Typography>
					<div className={classes.inputContainerStyles}>
						<p className={classes.label}>
							{t('domain:uncertainty.window-center')}
						</p>
						<InputRange
							handleChange={(e: ChangeEvent<HTMLInputElement>) => {
								if (setWindowCenter) {
									setWindowCenter(e.target.value);
								}
							}}
							value={windowCenter}
							label={windowCenter}
							name="Year"
							min="2020"
							max="2080"
							step={10}
						/>
					</div>
				</div>
			</Grid>
			<Grid item sm={12} md={8}>
				<div className={classes.chartRoot}>
					{/* <div className={classes.dropdownContainerStyles}> */}
					{/*	<Paragraph secondary>{t('domain:uncertainty.region')}</Paragraph> */}
					{/*	<DropdownOptions */}
					{/*		value="Global" */}
					{/*		style={{ marginLeft: '10px' }} */}
					{/*		data={regions} */}
					{/*	/> */}
					{/* </div> */}
					<div className={classes.regionHeaderContainerStyles}>
						<Line
							datasetKeyProvider={datasetKeyProvider}
							data={{
								type: 'scatter',
								datasets: densityData,
							}}
							options={{
								responsive: true,
								scales: {
									xAxes: [
										{
											type: 'linear',
											position: 'bottom',
										},
									],
								},
							}}
						/>
					</div>
				</div>
			</Grid>
		</>
	);
};

export default PanelUncertainty;
