// eslint-disable-next-line @typescript-eslint/no-explicit-any
const scenariosColors: any = {
	ssp119: '#e3e643',
	'ssp119-fill': 'rgba(252, 255, 201,0.7)',
	ssp126: '#a83232',
	'ssp126-fill': 'rgba(255, 201, 201,0.7)',
	ssp245: '#32a852',
	'ssp245-fill': 'rgba(201, 255, 205,0.7)',
	ssp370: '#4348e6',
	'ssp370-fill': 'rgba(201, 206, 255,0.7)',
	ssp434: '#43e6e6',
	'ssp434-fill': 'rgba(201, 255, 248,0.7)',
	ssp460: '#8c43e6',
	'ssp460-fill': 'rgba(231, 201, 255,0.7)',
	'ssp534-over': '#e64397',
	'ssp534-over-fill': 'rgba(255, 201, 233,0.7)',
	ssp585: '#e6a243',
	'ssp585-fill': 'rgba(255, 238, 201,0.7)',
};

export default scenariosColors;
