import { Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../services/useWindowDimensions';
import Paragraph from '../../Typography/Paragraph/Paragraph';
import Logo from './Logo/Logo';
import useStyles from './NavigationAppBarStyles';

const NavigationAppBar: FC = () => {
	const [visible, setVisible] = useState(false);
	const { width } = useWindowDimensions();
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<div className={classes.root}>
				<div className={classes.menuHeader}>
					<Logo />
					{width > 600 ? (
						<div>
							<Button className={classes.menuItem}>
								<Paragraph className={classes.paragraphText}>
									{t('common:navbar.items.model')}
								</Paragraph>
							</Button>
						</div>
					) : (
						<div className={classes.buttonContainer}>
							<Button
								className={classes.buttonMobileBars}
								onClick={() => setVisible(!visible)}
								id="mobile_item"
							>
								<i className="bars icon" />
							</Button>
						</div>
					)}
				</div>
			</div>
			{visible && width < 600 && (
				<div className={classes.mobileMenu}>
					<Button className={classes.menuItem}>
						<Paragraph className={classes.paragraphText}>
							{t('common:navbar.items.model')}
						</Paragraph>
					</Button>
				</div>
			)}
		</>
	);
};

export default NavigationAppBar;
