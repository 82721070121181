import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCustomSnackbar } from './snackbar';

export default (): [(error: ApolloError) => void] => {
	const { sendErrorMessage } = useCustomSnackbar();

	const onError = (apolloError: ApolloError): void => {
		const { graphQLErrors, networkError } = apolloError;
		if (graphQLErrors?.length > 0) {
			// just display the first error
			sendErrorMessage((graphQLErrors[0] as GraphQLError).message);
		}

		if (networkError) {
			const { result } = { ...networkError };
			if (result?.errors.length > 0) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				result?.errors.forEach((err: any) => {
					sendErrorMessage(err.message);
				});
			} else {
				sendErrorMessage(networkError.message);
			}
		}
	};

	return [onError];
};
