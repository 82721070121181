import { makeStyles } from '@material-ui/core';
import { colors } from '../../../theming/colors';

export default makeStyles({
	paragraph: ({ secondary }: { secondary?: boolean }) => ({
		fontFamily: 'Commissioner',
		fontSize: '14px',
		lineHeight: '19px',
		color: secondary ? colors.secondary : colors.primaryText,
	}),
});
