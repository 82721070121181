import { gql } from 'apollo-boost';
import { useEffect, useState } from 'react';
import useGraphQLLazyQuery from './hooks/useGraphQLLazyQuery';

const getNewSessionUuid = gql`
	query getNewSessionUuid {
		session {
			uuid
		}
	}
`;

export const useAuthentication = () => {
	const [sessionUuid, setSessionUuid] = useState(
		localStorage.getItem('sessionUuid')
	);

	const [fetchSessionUuid, { loading }] = useGraphQLLazyQuery(
		getNewSessionUuid,
		{
			onCompleted: (res) => {
				localStorage.setItem('sessionUuid', res.session.uuid);
				setSessionUuid(res.session.uuid);
			},
		}
	);

	useEffect(() => {
		if (sessionUuid === null) {
			fetchSessionUuid();
		}
	}, [sessionUuid]);

	return { sessionUuid, loading };
};
