import React, { FC } from 'react';
import SimpleSettingsSection from '../components/SimpleSettingsSection/SimpleSettingsSection';
import SingleAdvancedSettings from './Advanced/SingleAdvancedSettings/SingleAdvancedSettings';
import SingleSettingsProps from './SingleSettingsProps';

const SingleSettings: FC<SingleSettingsProps> = ({
	isSingleAdvancedMode,
	selectedType,
	setIsAltered,
}) => {
	if (isSingleAdvancedMode) {
		return <SingleAdvancedSettings setIsAltered={setIsAltered} />;
	}

	return (
		<SimpleSettingsSection
			setIsAltered={setIsAltered}
			runTypeSelected={selectedType}
		/>
	);
};

export default SingleSettings;
