import React from 'react';

const EmptyGraphTemperature = () => {
	return (
		<svg
			width="463"
			height="139"
			viewBox="0 0 463 139"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.995"
				d="M454.244 0H8.75651C3.92043 0 0 3.92043 0 8.75651V129.888C0 134.724 3.92043 138.645 8.75651 138.645H454.244C459.08 138.645 463 134.724 463 129.888V8.75651C463 3.92043 459.08 0 454.244 0Z"
				fill="#F8F8F8"
			/>
			<path
				d="M347.856 65.1104L347.856 54.1478L299.882 54.1478L299.882 65.1104L347.856 65.1104Z"
				fill="#F88A98"
				stroke="#F88A98"
				strokeWidth="2"
			/>
			<path
				opacity="0.55"
				d="M347.882 65.1104L347.882 54.1478L334.339 54.1478L334.339 65.1104L347.882 65.1104Z"
				fill="white"
			/>
			<path
				opacity="0.55"
				d="M326.277 65.1104L326.277 54.1478L299.855 54.1478L299.855 65.1104L326.277 65.1104Z"
				fill="white"
			/>
			<path
				d="M260.941 73.6117L260.941 62.6492L210.918 62.6492L210.918 73.6117L260.941 73.6117Z"
				fill="#FFA246"
				stroke="#FFA246"
				strokeWidth="2"
			/>
			<path
				opacity="0.55"
				d="M260.967 73.6117L260.967 62.6492L246.839 62.6492L246.839 73.6117L260.967 73.6117Z"
				fill="white"
			/>
			<path
				opacity="0.55"
				d="M229.943 73.6117L229.943 62.6492L210.891 62.6492L210.891 73.6117L229.943 73.6117Z"
				fill="white"
			/>
			<path
				d="M143.733 67.6515L143.733 56.689L108.743 56.689L108.743 67.6515L143.733 67.6515Z"
				fill="#CBCBCB"
				stroke="#CBCBCB"
				strokeWidth="2"
			/>
			<path
				opacity="0.55"
				d="M143.759 67.6515L143.759 56.689L133.887 56.689L133.887 67.6515L143.759 67.6515Z"
				fill="white"
			/>
			<path
				opacity="0.55"
				d="M118.002 67.6515L118.002 56.689L108.743 56.689L108.743 67.6515L118.002 67.6515Z"
				fill="white"
			/>
			<path d="M21.9263 106.684H438" stroke="#ADADAD" strokeMiterlimit="10" />
			<path
				d="M21.9263 106.684V30.9895"
				stroke="#ADADAD"
				strokeMiterlimit="10"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M438 106.189L439.484 106.932L438 107.674C438.244 107.21 438.244 106.622 438 106.189Z"
				fill="#ADADAD"
				stroke="#ADADAD"
				strokeWidth="0.625"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.9369 31.9789L21.679 30L22.4211 31.9789C21.9882 31.6836 21.4007 31.6836 20.9369 31.9789Z"
				fill="#ADADAD"
				stroke="#ADADAD"
				strokeWidth="0.625"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default EmptyGraphTemperature;
