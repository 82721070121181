import React, { FC } from 'react';
import ProbabilisticSettingsProps from './ProbabilisticSettingsProps';
import SimpleSettingsSection from '../components/SimpleSettingsSection/SimpleSettingsSection';

const ProbabilisticSettings: FC<ProbabilisticSettingsProps> = ({
	selectedType,
	setIsAltered,
}) => {
	return (
		<SimpleSettingsSection
			setIsAltered={setIsAltered}
			runTypeSelected={selectedType}
		/>
	);
};

export default ProbabilisticSettings;
