import React, { FC, useState } from 'react';
import { Transition, Visibility, VisibilityEventData } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import iconEmission from '../../../assets/icon-emission.png';
import iconModel from '../../../assets/icon-model.png';
import SemanticIconButton from '../../../components/Button/SemanticIconButton';
import useStyles from './PanelConfigurationStyles';
import EmissionScenarios from '../Panels/EmissionScenarios/EmissionScenarios';
import ModelSettings from '../Panels/ModelSettings/ModelSettings';
import { colors } from '../../../theming/colors';
import Panel from '../../../components/Page/Panel/Panel';

const PanelConfiguration: FC = () => {
	const [isEmissionActive, setIsEmissionActive] = useState(true);
	const [isModelSettingsActive, setIsModelSettingsActive] = useState(false);
	const [isContainerInViewport, setIsContainerInViewport] = useState(false);
	const { t } = useTranslation();

	const showOrHideContainer = (target: string) => {
		if (isContainerInViewport) {
			if (target === 'Emission') {
				setIsModelSettingsActive(false);
				setIsEmissionActive(true);
			}

			if (target === 'Model') {
				setIsEmissionActive(false);
				setIsModelSettingsActive(true);
			}
		}
	};

	const handleScrollSpy = (nothing: null, data: VisibilityEventData) => {
		const { bottomPassed, topPassed } = data.calculations;
		const hasScrollPassedElement = bottomPassed && topPassed;

		setIsContainerInViewport(!hasScrollPassedElement);
	};

	const [selectedScenarios, setSelectedScenarios] = useState<string[]>([]);
	const [isAltered, setIsAltered] = useState<boolean>(false);
	const visible = isEmissionActive || isModelSettingsActive;

	const classes = useStyles();
	return (
		<>
			<div className={classes.tabs}>
				<div className={classes.buttonContainer}>
					<SemanticIconButton
						active={isEmissionActive}
						onClick={() => {
							showOrHideContainer('Emission');
						}}
						image={iconEmission}
						label={t('domain:emissions.title')}
					/>
					<SemanticIconButton
						active={isModelSettingsActive}
						onClick={() => {
							showOrHideContainer('Model');
						}}
						image={iconModel}
						label={t('domain:model-settings.title')}
					/>
				</div>
			</div>
			<Visibility onUpdate={handleScrollSpy}>
				<Transition visible={visible} animation="slide down" duration={800}>
					<>
						{isEmissionActive && (
							<Panel
								backgroundColor={colors.secondary}
								component={EmissionScenarios as FC}
								isAltered={isAltered}
								setIsAltered={setIsAltered}
								selectedScenarios={selectedScenarios}
								setSelectedScenarios={setSelectedScenarios}
							/>
						)}
						{isModelSettingsActive && (
							<Panel
								backgroundColor={colors.secondary}
								component={ModelSettings as FC}
								isAltered={isAltered}
								setIsAltered={setIsAltered}
							/>
						)}
					</>
				</Transition>
			</Visibility>
		</>
	);
};

export default PanelConfiguration;
