import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { colors } from '../../../../theming/colors';
import Paragraph from '../../../Typography/Paragraph/Paragraph';
import { FooterListProps } from './FooterListProps';
import useStyles from './FooterListStyles';

const FooterList: FC<FooterListProps> = ({ footerListConfig }) => {
	const { push } = useHistory();

	const classes = useStyles();

	const currentYear = new Date().getFullYear();

	return (
		<List style={{ margin: 0 }}>
			{footerListConfig.map((item) => {
				return (
					<List.Item
						key={item.title}
						onClick={() => push(item.path)}
						className={classes.item}
						style={{ color: colors.secondary }}
						as="a"
					>
						<Paragraph secondary>{item.title}</Paragraph>
					</List.Item>
				);
			})}
			<List.Item className={classes.item} style={{ color: colors.secondary }}>
				<Paragraph secondary>@{currentYear}</Paragraph>
			</List.Item>
		</List>
	);
};

export default FooterList;
