import React, { FC, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import queries from '../../../../../components/Graphs/apollo/queries';
import { prepareData } from '../../../../../components/Graphs/helpers';
import scenariosColors from '../../../../../theming/scenariosColors';
import EmissionScenarioChartProps from './EmissionScenarioChartProps';
import { DataSet } from '../../../../../components/Graphs/types';
import { EmissionScenario } from '../../../../../types/graphql/EmissionScenario';
import useGraphQLLazyQuery from '../../../../../services/hooks/useGraphQLLazyQuery';
import useStyles, { chartOptions } from './EmissionScenarioChartStyles';

const EmissionScenarioChart: FC<EmissionScenarioChartProps> = ({
	selectedScenarios,
	variable,
	unit,
}) => {
	const [dataSet, setDataSet] = useState<DataSet[]>([]);

	const [fetchGraphData] = useGraphQLLazyQuery(
		queries.EmissionScenariosGraphData,
		{
			onCompleted: (res) => handleArrayData(res),
		}
	);

	useEffect(() => {
		if (variable && selectedScenarios && selectedScenarios.length > 0) {
			fetchGraphData({
				variables: {
					variableCode: variable,
					scenariosUuids: selectedScenarios,
				},
			});
		}
	}, [selectedScenarios, variable]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleArrayData = async (element: any) => {
		setDataSet([]);

		const { emissionScenarios } = element;

		const newArray: DataSet[] = [];

		await emissionScenarios.forEach((emissionScenario: EmissionScenario) => {
			newArray.push({
				uuid: emissionScenario.uuid,
				label: emissionScenario.name,
				data: prepareData(emissionScenario.data),
				fill: false,
				backgroundColor: scenariosColors[emissionScenario.code],
				borderColor: scenariosColors[emissionScenario.code],
				pointRadius: 1,
				lineTension: 0.2,
			});
		});

		setDataSet(newArray);
	};

	const labels =
		(dataSet[0]?.data && dataSet[0]?.data.map((el: { x: string }) => el.x)) ||
		[];

	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Line
				data={{
					labels,
					type: 'scatter',
					datasets: dataSet,
				}}
				options={chartOptions(unit)}
			/>
		</div>
	);
};

export default EmissionScenarioChart;
