import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import SemanticIconButton from '../../../../components/Button/SemanticIconButton';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import useStyles from './PanelShareStyles';
import { useCustomSnackbar } from '../../../../services/snackbar';
import downloadHelper from './downloadHelper';
import { ModelRun } from '../../../../types/graphql/RunHistory';
import PanelTemplateProps from '../../../../components/Page/Panel/types';

const PanelShare: FC<PanelTemplateProps> = ({ activeModelRuns }) => {
	const { t } = useTranslation();
	const { sendMessage } = useCustomSnackbar();

	const triggerAllDownloads = () => {
		activeModelRuns?.map((activeModelRun: ModelRun) =>
			downloadHelper({
				modelRunId: activeModelRun.uuid,
				modelRunName: activeModelRun.name,
			})
		);
	};

	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={6}>
				<PanelHeader>{t('domain:share.title')}</PanelHeader>
				<Paragraph>{t('domain:share.description')}</Paragraph>
			</Grid>
			<Grid item xs={12} sm={12} md={6}>
				<div className={classes.buttonContainer}>
					<SemanticIconButton
						onClick={triggerAllDownloads}
						icon="download icon"
						label={t('domain:share.download-label')}
					/>
					<CopyToClipboard
						onCopy={(text: string) =>
							sendMessage(t('domain:share.success-copied', { text }), 'success')
						}
						text={window.location.href}
					>
						<SemanticIconButton
							icon="share alternate icon"
							label={t('domain:share.share-label')}
						/>
					</CopyToClipboard>
				</div>
			</Grid>
		</>
	);
};

export default PanelShare;
