import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		backgroundColor: '#fff',
		height: '100px',
		width: '100%',
	},
	mobileMenu: {
		backgroundColor: '#fff',
		width: '100%',
		height: '45px',
		display: 'flex',
	},
	menuItem: {
		fontFamily: 'Commissioner',
		marginRight: '10px',
	},
	paragraphText: {
		fontSize: '24px',
		textTransform: 'none',
	},
	menuHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	buttonContainer: {
		alignSelf: 'center',
	},
	buttonMobileBars: {
		fontSize: '25px',
	},
});
