import React, { FC } from 'react';
import clsx from 'clsx';
import ParagraphTextProps from './ParagraphTextProps';
import useStyles from './ParagraphTextStyles';

const Paragraph: FC<ParagraphTextProps> = ({
	children,
	style,
	secondary,
	className,
}) => {
	const classes = useStyles({ secondary });
	return (
		<p className={clsx(className, classes.paragraph)} style={style}>
			{children}
		</p>
	);
};

export default Paragraph;
