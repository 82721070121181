import { makeStyles } from '@material-ui/core';
import { themeValues } from '../../../theming/themeValues';

export default makeStyles({
	root: ({ backgroundColor }: { backgroundColor: string }) => ({
		minHeight: themeValues.panelMinHeight,
		backgroundColor,
		padding: '50px 0px',
	}),
	column: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		maxWidth: '1200px',
	},
	grid: {
		'@media only screen and (max-width: 1280px)': {
			padding: '0 32px',
		},
	},
});
