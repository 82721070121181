import React, { ChangeEvent, FC } from 'react';
import { Typography } from '@material-ui/core';
import InputRange from '../InputRange/InputRange';
import ModelSettingAdvItemProps from './ModelSettingAdvItemProps';
import useStyles from './ModelSettingAdvItemStyles';

const ModelSettingAdvItem: FC<ModelSettingAdvItemProps> = ({
	uuid,
	title,
	label,
	value,
	min,
	max,
	step,
	handleChange,
	name,
}) => {
	const classes = useStyles();

	return (
		<div id={uuid} className={classes.root}>
			<div className={classes.label}>
				<Typography variant="h3">{title}</Typography>
			</div>
			<InputRange
				isInputEditable
				label={label}
				handleChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
				value={value}
				name={name}
				min={min}
				max={max}
				step={step}
			/>
		</div>
	);
};

export default ModelSettingAdvItem;
