import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyGraphTemperature from '../../Graphs/EmptyGraph/EmptyGraphTemperature';
import EmptyGraphUncertainty from '../../Graphs/EmptyGraph/EmptyGraphUncertainty';
import PanelHeader from '../Panel/PanelHeader';
import Paragraph from '../../Typography/Paragraph/Paragraph';
import useStyles from './EmptyStatePanelStyles';
import EmptyStatePanelProps from './EmptyStatePanelProps';

const EmptyStatePanel: FC<EmptyStatePanelProps> = ({
	classes: parentClasses,
	panel,
}) => {
	const isUncertainty = panel === 'uncertainty';
	const classes = useStyles({ isBright: isUncertainty });
	const { t } = useTranslation();

	return (
		<>
			<Grid item xs={12} sm={6}>
				<PanelHeader isBright={isUncertainty}>
					{t(`domain:${panel}.empty-state.title`)}
				</PanelHeader>

				<Paragraph secondary={isUncertainty}>
					{t(`domain:${panel}.empty-state.description`)}
				</Paragraph>
			</Grid>
			<Grid
				container
				className={parentClasses.columnRight}
				item
				sm={12}
				md={6}
				lg={6}
			>
				<Grid container className={parentClasses.chartRoot}>
					<Grid item xs={12} md={12} lg={6}>
						<div className={classes.emptyGraph}>
							{isUncertainty ? (
								<EmptyGraphUncertainty />
							) : (
								<EmptyGraphTemperature />
							)}
						</div>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default EmptyStatePanel;
