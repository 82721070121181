import React, { CSSProperties, FC } from 'react';
import { Button, Image } from 'semantic-ui-react';
import { colors } from '../../theming/colors';
import SemanticIconButtonProps from './SemanticIconButtonProps';

const buttonContainerStyles: CSSProperties = {
	borderRadius: 0,
	margin: 0,
	width: '230px',
	height: '50px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-evenly',
	alignItems: 'center',
	boxShadow: 'none',
	backgroundColor: '#fff',
};

const bigButtonStyle: CSSProperties = {
	width: '200px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-around',
	alignItems: 'center',
	margin: 'auto',
	marginBottom: '20px',
};

const textStyles: CSSProperties = {
	fontFamily: 'Noticia Text',
	fontSize: '16px',
	color: '#2c2c2c',
};

const imageStyles: CSSProperties = {
	width: '26px',
	height: 'auto',
	color: '#2c2c2c !important',
};

const SemanticIconButton: FC<SemanticIconButtonProps> = ({
	icon,
	image,
	label,
	onClick,
	active,
}) => {
	return (
		<Button
			active={active}
			size={icon ? 'massive' : 'medium'}
			basic
			style={icon ? bigButtonStyle : buttonContainerStyles}
			hoverStyle={icon ? bigButtonStyle : buttonContainerStyles}
			onClick={onClick}
		>
			{icon ? (
				<i style={{ color: colors.primary }} className={icon} />
			) : (
				<Image style={imageStyles} src={image} avatar />
			)}
			<p style={textStyles}>{label}</p>
		</Button>
	);
};

export default SemanticIconButton;
