import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../../theming/colors';

export default makeStyles({
	grid: {
		margin: 'auto',
		padding: '20px 50px',
		'@media (max-width: 768px)': {
			padding: 0,
		},
		marginBottom: '20px',
	},
	dropdown: {
		width: '200px',
		fontSize: '1.1em',
	},
	parametersText: {
		fontSize: '16px',
		color: colors.secondaryText,
	},
});
