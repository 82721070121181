import React, { ChangeEvent, FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import ModelSettingAdvItem from '../../../../../../../components/Form/ModelSettingAdvItem/ModelSettingAdvItem';
import useStyles from './SingleAdvancedSettingsStyles';
import { useAuthentication } from '../../../../../../../services/useAuthentication';
import queries from '../../../apollo/queries';
import mutations from '../../../apollo/mutations';
import Loading from '../../../../../../../components/Loading/Loading';
import { RunSettingModelParameter } from '../../../../../../../types/graphql/RunSetting';
import useGraphQLQuery from '../../../../../../../services/hooks/useGraphQLQuery';
import useGraphQLMutation from '../../../../../../../services/hooks/useGraphQLMutation';
import SingleAdvancedSettingsProps from './SingleAdvancedSettingsProps';

const SingleAdvancedSettings: FC<SingleAdvancedSettingsProps> = ({
	setIsAltered,
}) => {
	const [modelParametersData, setModelParametersData] = useState([]);
	const { sessionUuid } = useAuthentication();

	const { loading } = useGraphQLQuery(queries.runSettingModelParameters, {
		variables: {
			sessionUuid,
		},
		onCompleted: (res) => {
			setModelParametersData(res.runSetting.modelParameters);
		},
	});

	const [updateModelParameterSet] = useGraphQLMutation(
		mutations.updateRunSettingModelParameter,
		{
			onCompleted: (res) => {
				setModelParametersData(res.updateRunSetting.updateModelParameter);
				setIsAltered(true);
			},
		}
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let timer: any;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			updateModelParameterSet({
				variables: {
					sessionUuid,
					parameterUuid: e.target.name,
					parameterValue: Number(e.target.value),
				},
			});
		}, 300);
	};

	const classes = useStyles();

	return (
		<Loading loading={loading}>
			<Grid className={classes.gridContainer} container>
				{modelParametersData.length > 0 &&
					modelParametersData.map((parameter: RunSettingModelParameter) => {
						return (
							<Grid key={parameter.uuid} md={12} lg={6}>
								<ModelSettingAdvItem
									isInputEditable
									name={parameter.uuid}
									title={parameter.name}
									label={parameter.name}
									min={parameter.min.toString()}
									max={parameter.max.toString()}
									handleChange={handleChange}
									value={parameter.value}
									step={0.1}
								/>
							</Grid>
						);
					})}
			</Grid>
		</Loading>
	);
};

export default SingleAdvancedSettings;
