import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../theming/colors';

export default makeStyles({
	paragraph: ({ isActive }: { isActive: boolean }) => ({
		marginBottom: '0px',
		fontSize: '1.2rem',
		color: isActive ? '#fff' : 'inherit',
		padding: '5px 0',
		letterSpacing: '0.13132px',
	}),
	actionContainer: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
	},
	item: { flex: 1, cursor: 'pointer', padding: '0px' },
	activeItem: {
		flex: 1,
		backgroundColor: colors.secondaryText,
		cursor: 'pointer',
		padding: '0px',
	},
	itemContent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
	},
	tooltip: {
		backgroundColor: colors.secondaryText,
	},
	arrowTooltip: {
		color: colors.secondaryText,
	},
	input: {
		padding: '6.5px',
		fontSize: '1.2rem',
		fontFamily: 'Commissioner',
	},
	outlineRoot: {
		margin: '-1px 0 0 -7.5px',
		padding: '0.5px 0 0.5px 1px',
	},
	button: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
		padding: '16px 16px',
	},
});
