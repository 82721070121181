import React, { ChangeEvent, CSSProperties, FC } from 'react';
import { Form, Input } from 'semantic-ui-react';
import InputRangeProps from './InputRangeProps';

const formContainerStyles: CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '10px',
	marginLeft: '20px',
};

const inputStyles: CSSProperties = {
	color: '#fff',
};

const InputRange: FC<InputRangeProps> = ({
	handleChange,
	label,
	name,
	min,
	max,
	value,
	step,
	isInputEditable,
}) => {
	return (
		<Form style={formContainerStyles}>
			<Form.Input
				className="ui range"
				style={inputStyles}
				label={!isInputEditable && label}
				min={min}
				max={max}
				name={name}
				onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
				step={step}
				type="range"
				defaultValue={value}
			/>
			{isInputEditable && (
				<Input
					value={value}
					name={name}
					onChange={(e) => {
						handleChange(e);
					}}
				/>
			)}
		</Form>
	);
};

export default InputRange;
