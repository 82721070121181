import { makeStyles } from '@material-ui/core';

export default makeStyles({
	loading: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '10px',
		height: '600px',
	},
});
