import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		flexGrow: 1,
		margin: '0 20px',
	},
	paragraph: {
		width: '300px',
	},
});
