import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		minWidth: 280,
		margin: 'auto',
		backgroundColor: '#ffffff',
		borderBottom: 0,
	},
});
