import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import React, { FC } from 'react';

interface LoadingProps extends CircularProgressProps {
	loading: boolean;
}

const Loading: FC<LoadingProps> = ({ loading, size, className, children }) => {
	if (loading) {
		return (
			<div className={className}>
				<CircularProgress size={size} />
			</div>
		);
	}

	return <>{children}</>;
};

export default Loading;
