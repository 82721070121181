import { gql } from 'apollo-boost';

const EmissionScenariosAndVariables = gql`
	query EmissionScenarios {
		emissionScenarios {
			uuid
			code
			name
		}
		emissionVariables {
			uuid
			name
			code
			unit
		}
	}
`;

const RunSettingEmissionScenarios = gql`
	query RunSettingEmissionScenarios($sessionUuid: String!) {
		runSetting(sessionUuid: $sessionUuid) {
			uuid
			emissionScenarios {
				uuid
			}
		}
	}
`;

export default {
	EmissionScenariosAndVariables,
	RunSettingEmissionScenarios,
};
