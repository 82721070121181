/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import DropdownOptions from '../../../../components/Form/DropdownOptions/DropdownOptions';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import PanelTemplateProps from '../../../../components/Page/Panel/types';
import useStyles from './PanelClimateFutureStyles';
import { prepareData } from '../../../../components/Graphs/helpers';
import useGraphQLQuery from '../../../../services/hooks/useGraphQLQuery';
import queries from './apollo/queries';
import {
	convertDataIntoDropdownFormat,
	getIndexOrMaxAllowed,
	getProbabilisticDataSet,
} from './helpers';
import { DataSet } from '../../../../components/Graphs/types';
import {
	ModelRun,
	ModelRunDataClimateFuture,
} from '../../../../types/graphql/RunHistory';
import { climateFutureColors } from '../../../../theming/climateFutureColors';
import ClimateFutureChart from './ClimateFutureChart/ClimateFutureChart';

const PanelClimateFuture: FC<PanelTemplateProps> = ({
	selectedVariable,
	setSelectedVariable,
	activeModelRuns,
}) => {
	const [modelRunNames, setModelRunNames] = useState<string[]>([]);
	const [labels, setLabels] = useState<string[]>();
	const [combinedDataset, setCombinedDataset] = useState<DataSet[]>([]);

	const { t } = useTranslation();

	useEffect(() => {
		if (activeModelRuns) {
			const currentDataset: DataSet[] = [];

			const adaptedArray = activeModelRuns.map((modelRun: ModelRun) => {
				return modelRun.name;
			});

			setModelRunNames(adaptedArray);

			activeModelRuns.forEach((modelRun: ModelRun, index: number) => {
				const readyData = modelRun.data.climateFuture.map(
					(singleDataset: ModelRunDataClimateFuture[]) => {
						return prepareData(singleDataset);
					}
				);

				const modelRunLabel = modelRun.name;

				if (modelRun.data.methods.length > 2) {
					const arrayProbabilistic = getProbabilisticDataSet(
						readyData,
						modelRunLabel,
						modelRun.data.methods,
						index
					);

					currentDataset.push(...arrayProbabilistic);
				} else {
					currentDataset.push({
						borderColor:
							climateFutureColors[getIndexOrMaxAllowed(index)].single,
						data: readyData[0],
						label: modelRunLabel,
						fill: false,
					});
				}
				setLabels(readyData[0]?.map((el: { x: string }) => el.x) || []);
			});

			setCombinedDataset(currentDataset);
		}
	}, [activeModelRuns]);

	const { data: variablesData } = useGraphQLQuery(queries.climateVariables);

	const { climateVariables } = variablesData || {};

	const dropdownFormat = convertDataIntoDropdownFormat(climateVariables);

	const handleChange = (
		e: SyntheticEvent<HTMLElement, Event>,
		dropdownContent: DropdownProps
	) => {
		if (setSelectedVariable) {
			setSelectedVariable(dropdownContent.value as string);
		}
	};

	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={12} md={4}>
				<PanelHeader>{t('domain:climate-futures.title')}</PanelHeader>
				<Paragraph>{t('domain:climate-futures.description')}</Paragraph>

				<div className={classes.dropdownContainer}>
					<Typography variant="h3">
						{t('domain:climate-futures.visualization-label')}
					</Typography>
					<DropdownOptions
						handleChange={handleChange}
						value={selectedVariable || ''}
						className={classes.dropdown}
						data={dropdownFormat}
					/>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<div className={classes.chartRoot}>
					<ClimateFutureChart
						labels={labels}
						data={combinedDataset}
						modelRunNames={modelRunNames}
					/>
				</div>
			</Grid>
		</>
	);
};

export default PanelClimateFuture;
