import { gql } from 'apollo-boost';

const climateVariables = gql`
	query {
		climateVariables {
			uuid
			code
			name
			apiCode
			unit
		}
	}
`;

export default {
	climateVariables,
};
