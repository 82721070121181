import React, { FC } from 'react';
import { Image } from 'semantic-ui-react';
import { Grid } from '@material-ui/core';
import {
	LeftFooterMenuConfig,
	RightFooterMenuConfig,
} from '../../../config/footerMenu';
import FooterList from './FooterList/FooterList';
import HyperbellLogo from '../../../assets/hyper_logo.png';
import useStyles from './FooterStyles';
import Paragraph from '../../Typography/Paragraph/Paragraph';

const Footer: FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.footerRoot}>
			<Grid className={classes.footerGrid} container spacing={1}>
				<Grid item xs={12} md={6}>
					<FooterList footerListConfig={LeftFooterMenuConfig} />
				</Grid>
				<Grid className={classes.gridRight} item xs={12} md={6}>
					<FooterList footerListConfig={RightFooterMenuConfig} />
				</Grid>
			</Grid>
			<Paragraph className={classes.bottomText}>
				<Image className={classes.image} src={HyperbellLogo} avatar />
				Made by HYPERBELL
			</Paragraph>
		</div>
	);
};

export default Footer;
