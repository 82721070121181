import { gql } from 'apollo-boost';

const UpdateRunSettingEmissionScenarios = gql`
	mutation UpdateUserEmissionScenarios(
		$sessionUuid: String!
		$emissionScenarios: [String!]
	) {
		updateRunSetting(sessionUuid: $sessionUuid) {
			uuid
			updateEmissionScenarios(uuids: $emissionScenarios) {
				uuid
			}
		}
	}
`;

const CreateModelRun = gql`
	mutation CreateModelRun($sessionUuid: String!) {
		createModelRun(sessionUuid: $sessionUuid) {
			uuid
			name
		}
	}
`;

export default {
	UpdateRunSettingEmissionScenarios,
	CreateModelRun,
};
