import React, { FC, useEffect, useState } from 'react';
import Teaser from '../../components/Page/Teaser/Teaser';
import Footer from '../../components/Page/Footer/Footer';
import NavigationAppBar from '../../components/Page/Header/NavigationAppBar';
import PanelClimateFuture from './Panels/ClimateFuture/PanelClimateFuture';
import PanelShare from './Panels/Share/PanelShare';
import PanelUncertainty from './Panels/Uncertainty/PanelUncertainty';
import ModelRunHistory from './ModelRunHistory/ModelRunHistory';
import PanelConfiguration from './ConfigurationTabs/PanelConfiguration';
import { colors } from '../../theming/colors';
import { useAuthentication } from '../../services/useAuthentication';
import Loading from '../../components/Loading/Loading';
import useStyles from './HomeStyles';
import useGraphQLLazyQuery from '../../services/hooks/useGraphQLLazyQuery';
import { ModelRun } from '../../types/graphql/RunHistory';
import queries from './apollo/queries';
import PanelTemperature from './Panels/Temperature/PanelTemperature';
import Panel from '../../components/Page/Panel/Panel';

const Home: FC = () => {
	const [selectedVariable, setSelectedVariable] = useState<string>(
		'surfaceTemperature'
	);
	const [activeModelRuns, setActiveModelRuns] = useState<ModelRun[]>([]);
	const [windowCenter, setWindowCenter] = useState('2080');
	const { sessionUuid, loading } = useAuthentication();
	const classes = useStyles();

	const [fetchRunHistory, { data: runHistoryData }] = useGraphQLLazyQuery(
		queries.runHistory
	);

	useEffect(() => {
		if (selectedVariable && sessionUuid) {
			fetchRunHistory({
				variables: {
					sessionUuid,
					variableCode: selectedVariable,
					centerYear: Number(windowCenter),
				},
			});
		}
	}, [selectedVariable, sessionUuid, windowCenter]);

	useEffect(() => {
		if (runHistoryData?.runHistory?.modelRuns) {
			const filterActives = runHistoryData.runHistory.modelRuns.filter(
				(modelRun: ModelRun) => modelRun.isActive
			);
			setActiveModelRuns(filterActives);
		}
	}, [runHistoryData]);

	const isThereASessionUuid = loading || !sessionUuid;

	return (
		<>
			<NavigationAppBar />
			<Teaser />
			<Loading
				className={classes.loading}
				size={40}
				loading={isThereASessionUuid}
			>
				<ModelRunHistory
					windowCenter={windowCenter}
					selectedVariable={selectedVariable}
				/>
				<PanelConfiguration />
				<Panel
					component={PanelClimateFuture as FC}
					backgroundColor={colors.whiteBackground}
					selectedVariable={selectedVariable}
					setSelectedVariable={setSelectedVariable}
					activeModelRuns={activeModelRuns}
				/>
				<Panel
					component={PanelUncertainty as FC}
					backgroundColor={colors.darkBackground}
					setWindowCenter={setWindowCenter}
					windowCenter={windowCenter}
					activeModelRuns={activeModelRuns}
				/>
				<Panel
					backgroundColor={colors.whiteBackground}
					component={PanelTemperature as FC}
					activeModelRuns={activeModelRuns}
				/>
			</Loading>

			<Panel
				activeModelRuns={activeModelRuns}
				component={PanelShare as FC}
				backgroundColor={colors.lightBackground}
			/>
			{/* <Partners /> */}
			<Footer />
		</>
	);
};

export default Home;
