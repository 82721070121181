/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider, WithSnackbarProps } from 'notistack';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './theming/index.css';
// import reportWebVitals from './reportWebVitals';
import AppRouter from './routing/AppRouter';
import customTheme from './theming/customMuiTheme';
import './config/i18n/configuration';

const client = new ApolloClient({
	uri: process.env.REACT_APP_API_GRAPHQL_URL,
	cache: new InMemoryCache({
		dataIdFromObject: (object: any) => object.uuid,
		addTypename: false,
		typePolicies: {
			Query: {
				fields: {
					updateRunSetting: {
						merge(existing = [], incoming: any) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
		},
	}),
});

const notificationStackRef = React.createRef<WithSnackbarProps>();
const onClickDismiss = (key: string | number | undefined) => () => {
	if (notificationStackRef.current) {
		notificationStackRef.current.closeSnackbar(key);
	}
};

ReactDOM.render(
	<ApolloProvider client={client}>
		<React.StrictMode>
			<ThemeProvider theme={customTheme}>
				<CssBaseline />
				<SnackbarProvider
					classes={{
						variantSuccess: 'variantsuccess-snackbar',
					}}
					ref={notificationStackRef as RefObject<SnackbarProvider>}
					maxSnack={3}
					action={(key) => (
						<IconButton
							key="close"
							aria-label="close"
							style={{ color: 'white' }}
							onClick={onClickDismiss(key)}
						>
							<CloseIcon />
						</IconButton>
					)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<AppRouter />
				</SnackbarProvider>
			</ThemeProvider>
		</React.StrictMode>
	</ApolloProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your src, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
