import React, { FC } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { colors } from '../../../theming/colors';
import DropdownOptionProps from './DropdownOptionsProps';

const DropdownOptions: FC<DropdownOptionProps> = ({
	data,
	style,
	value,
	handleChange,
	className,
}) => {
	return (
		<Dropdown
			className={className}
			style={{ ...style, border: `2px solid ${colors.primary}` }}
			placeholder="Select"
			value={value}
			fluid
			selection
			options={data || []}
			onChange={handleChange}
		/>
	);
};

export default DropdownOptions;
