import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownParameterSet from '../DropdownParameterSet/DropdownParameterSet';
import SimpleSettingsSectionProps from './SimpleSettingsSectionProps';
import useStyles from './SimpleSettingsSectionStyles';

const SimpleSettingsSection: FC<SimpleSettingsSectionProps> = ({
	runTypeSelected,
	setIsAltered,
}) => {
	const { t } = useTranslation();

	const classes = useStyles();
	return (
		<Grid className={classes.grid} container>
			<Grid xs={12} md={6} spacing={2} item container>
				<Grid item md={6} xs={12}>
					<p className={classes.parametersText}>
						{t('domain:model-settings.parameters')}
					</p>
				</Grid>
				<Grid item md={6} xs={12}>
					<DropdownParameterSet
						setIsAltered={setIsAltered}
						className={classes.dropdown}
						runTypeSelected={runTypeSelected}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SimpleSettingsSection;
