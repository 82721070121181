import { VictoryChartDataset } from '../../domain/Home/Panels/Temperature/components/TemperatureGraph/types';
import { ModelRunDataClimateFuture } from '../../types/graphql/RunHistory';
import { Coord } from './Polygon/PolygonProps';

export const prepareData = (array: ModelRunDataClimateFuture[]) => {
	return array.map((element: ModelRunDataClimateFuture) => {
		return {
			x: `${element.year}`,
			y: element.value,
		};
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPoints = (data: VictoryChartDataset[], scale: any) => {
	return data.reduce(
		(pointStr: string, coord: Coord) =>
			`${pointStr} ${scale.x(coord.x)},${scale.y(coord.y)}`,
		''
	);
};
