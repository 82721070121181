import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		minWidth: '150px',
		paddding: '20px',
	},
	paragraph: {
		fontSize: '11px',
		color: '#fff',
	},
});
