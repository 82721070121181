const greenBlue = '#005e72';
const greenBlueLight = '#7de5fa';
const greenBlueSuperLight = '#c9f6ff';
const lightGray = '#f0f0f0';
const lightGrayText = '#acacac';
const lightGrayBackground = '#f2f2f2';
const darkGray = '#505050';
const darkGrayText = '#7A7A7A';
const white = '#fff';

export const colors = {
	primary: greenBlue,
	primaryText: darkGrayText,
	primaryLight: greenBlueLight,
	primarySuperLight: greenBlueSuperLight,
	secondary: lightGray,
	secondaryText: lightGrayText,
	whiteBackground: white,
	darkBackground: darkGray,
	lightBackground: lightGrayBackground,
};
