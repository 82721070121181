import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../theming/colors';

export default makeStyles({
	titles: {
		color: colors.secondary,
	},
	dropdownContainer: {
		margin: '90px 50px',
		fontSize: '1.1em',
		textAlign: 'center',
		alignSelf: 'center',
	},
	dropdownContainerStyles: {
		marginTop: '30px',
		width: '300px',
		display: 'flex',
		alignItems: 'center',
		alignSelf: 'center',
		justifyContent: 'space-around',
	},
	regionHeaderContainerStyles: {
		marginTop: 20,
		padding: 20,
		borderRadius: '5px',
		width: '100%',
		backgroundColor: colors.secondary,
	},
	rangeInputStyles: {
		marginLeft: '10px',
	},
	inputContainerStyles: {
		marginBottom: '15px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-evenly',
	},
	chartRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginLeft: 30,
		padding: 30,
		'@media only screen and (max-width: 1025px)': {
			padding: '60px 0',
			margin: 0,
		},
		'@media (max-width: 768px)': {
			padding: 0,
		},
	},
	label: {
		marginTop: '40px !important',
		fontSize: '15px',
		color: colors.secondary,
	},
});
