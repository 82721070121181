import { makeStyles } from '@material-ui/core';

export default makeStyles({
	chartRoot: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '50px',
		marginLeft: 30,
		padding: 30,
		'@media only screen and (max-width: 1025px)': {
			padding: '60px 0',
			margin: 0,
		},
		'@media (max-width: 768px)': {
			padding: 0,
		},
	},
});
