import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { colors } from '../../../theming/colors';

interface CustomHeaderProps {
	className?: string;
	isBright?: boolean;
}

const makeStyles1 = makeStyles({
	color: ({ isBright }: { isBright: boolean | undefined }) => ({
		color: isBright ? colors.secondary : '#2c2c2c',
	}),
});

const PanelHeader: FC<CustomHeaderProps> = ({
	children,
	className,
	isBright,
}) => {
	const classes = makeStyles1({ isBright });
	return (
		<Typography variant="h2" className={clsx(classes.color, className)}>
			{children}
		</Typography>
	);
};

export default PanelHeader;
