import { colors } from './colors';

export const climateFutureColors = [
	{
		single: colors.primary,
		probabilistic: {
			background: 'rgba(201, 246, 255, 0.7)',
			line: colors.primary,
		},
	},
	{
		single: '#007217',
		probabilistic: {
			background: 'rgba(201, 255, 205,0.7)',
			line: '#007217',
		},
	},
	{
		single: '#a6ad02',
		probabilistic: {
			background: 'rgba(252, 255, 201,0.7)',
			line: '#a6ad02',
		},
	},
	{
		single: '#ad6302',
		probabilistic: {
			background: 'rgba(255, 236, 201,0.7)',
			line: '#ad6302',
		},
	},
	{
		single: '#ad0202',
		probabilistic: {
			background: 'rgba(255, 201, 201,0.7)',
			line: '#ad0202',
		},
	},
	{
		single: '#02ad9f',
		probabilistic: {
			background: 'rgba(201, 255, 248,0.7)',
			line: '#02ad9f',
		},
	},
	{
		single: '#0227ad',
		probabilistic: {
			background: 'rgba(201, 206, 255,0.7)',
			line: '#0227ad',
		},
	},
	{
		single: '#6602ad',
		probabilistic: {
			background: 'rgba(231, 201, 255,0.7)',
			line: '#6602ad',
		},
	},
	{
		single: '#a202ad',
		probabilistic: {
			background: 'rgba(254, 201, 255,0.7)',
			line: '#a202ad',
		},
	},
	{
		single: '#000000',
		probabilistic: {
			background: 'rgba(224, 224, 224,0.7)',
			line: '#000000',
		},
	},
];
