import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		marginTop: 30,
		padding: 20,
		borderRadius: '5px',
		backgroundColor: '#fdfdfd',
	},
});

export const chartOptions = (chartLabel: string) => {
	return {
		responsive: true,
		layout: {
			padding: {
				right: 14,
			},
		},
		scales: {
			xAxes: [
				{
					type: 'time',
					ticks: {
						padding: 10,
					},
					time: {
						format: 'YYYY',
						stepSize: 10,
						min: '2000',
					},
					gridLines: {
						drawTicks: false,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						padding: 10,
					},
					scaleLabel: {
						display: true,
						labelString: chartLabel,
					},
					gridLines: {
						drawTicks: false,
					},
				},
			],
		},
	};
};
