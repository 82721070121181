import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import DropdownOptions from '../../../../../../components/Form/DropdownOptions/DropdownOptions';
import Loading from '../../../../../../components/Loading/Loading';
import { useAuthentication } from '../../../../../../services/useAuthentication';
import { RunSettingModelParameterSet } from '../../../../../../types/graphql/RunSetting';
import mutations from '../../apollo/mutations';
import queries from '../../apollo/queries';
import { handleData, getElementCode } from './helpers';
import {
	DropdownDataType,
	DropdownParameterSetProps,
} from './DropdownParameterSetProps';
import useGraphQLQuery from '../../../../../../services/hooks/useGraphQLQuery';
import useGraphQLMutation from '../../../../../../services/hooks/useGraphQLMutation';

const DropdownParameterSet: FC<DropdownParameterSetProps> = ({
	runTypeSelected,
	className,
	setIsAltered,
}) => {
	const [dropdownData, setDropdownData] = useState<DropdownDataType[]>([]);
	const [selectedValueState, setSelectedValueState] = useState<string>('');
	const { sessionUuid } = useAuthentication();

	const { data, loading } = useGraphQLQuery(queries.runSettingParameterSets, {
		variables: {
			sessionUuid,
		},
		onCompleted: (res) => {
			updateDataAndSelectedValue(
				res.runSetting.modelParameterSets,
				runTypeSelected
			);
		},
	});

	const [updateParameterSets] = useGraphQLMutation(
		mutations.updateRunSettingParameterSets,
		{
			onCompleted: (res) => {
				updateDataAndSelectedValue(
					res.updateRunSetting.updateModelParameterSet,
					runTypeSelected
				);
				setIsAltered(true);
			},
		}
	);

	useEffect(() => {
		if (data) {
			updateDataAndSelectedValue(
				data.runSetting.modelParameterSets,
				runTypeSelected
			);
		}
	}, [runTypeSelected, data]);

	const updateDataAndSelectedValue = (
		modelParameters: RunSettingModelParameterSet[],
		runType?: string
	) => {
		const preparedData = handleData(modelParameters, runType);
		setDropdownData(preparedData);

		const elementCode = getElementCode(modelParameters, runType);
		setSelectedValueState(elementCode);
	};

	const handleChange = (
		e: SyntheticEvent<HTMLElement, Event>,
		dropdownContent: DropdownProps
	) => {
		updateParameterSets({
			variables: {
				sessionUuid,
				parameterSetCode: dropdownContent.value,
			},
		});
	};

	return (
		<Loading
			loading={loading || dropdownData?.length === 0 || !selectedValueState}
		>
			<DropdownOptions
				className={className}
				data={dropdownData}
				handleChange={handleChange}
				value={selectedValueState}
			/>
		</Loading>
	);
};

export default DropdownParameterSet;
