import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../theming/colors';

export default makeStyles({
	item: {
		color: colors.secondary,
		marginBottom: '10px',
		fontSize: '12px',
		'@media only screen and (max-width: 768px)': {
			textAlign: 'center',
		},
	},
});
