import axios from 'axios';

interface DownloadHelperTypes {
	modelRunId: string;
	modelRunName: string;
}

export default ({ modelRunId, modelRunName }: DownloadHelperTypes) => {
	axios({
		url: `${process.env.REACT_APP_API_REST_URL}/download/model-run/${modelRunId}`,
		method: 'GET',
		responseType: 'blob',
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${modelRunName}.csv`);
		document.body.appendChild(link);
		link.click();
	});
};
