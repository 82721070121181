import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = createMuiTheme({
	typography: {
		fontFamily: 'Commissioner',
		h1: {
			fontFamily: 'Noticia Text',
		},
		h2: {
			fontSize: '28px',
			fontFamily: 'Noticia Text',
			color: '#2c2c2c',
			margin: '10px 0 10px',
		},
		h3: {
			fontFamily: 'Noticia Text',
			fontSize: '20px',
			color: '#2c2c2c',
			margin: '10px 0 10px',
		},
		h4: {
			fontFamily: 'Noticia Text',
		},
	},
	palette: {
		primary: {
			main: '#005e72',
			contrastText: '#fff',
		},
		secondary: {
			main: '#f0f0f0',
			contrastText: '#505050',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 768,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

export default customTheme;
