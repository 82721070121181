import { makeStyles } from '@material-ui/core';

export default makeStyles({
	rowContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		flex: 1,
	},
	left: {
		flex: 1,
	},
	right: {
		textAlign: 'right',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
});
