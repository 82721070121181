import { VariantType, useSnackbar } from 'notistack';
import { SNACKBAR_TIMEOUT } from '../../config/constants/constants';

export interface UseCustomSnackbar {
	sendErrorMessage: (message: string) => void;
	sendMessage: (message: string, variant: VariantType) => void;
}

export default (): UseCustomSnackbar => {
	const { enqueueSnackbar } = useSnackbar();

	const sendMessage = (message: string, variant: VariantType): void => {
		enqueueSnackbar(message, {
			variant,
			autoHideDuration: SNACKBAR_TIMEOUT,
		});
	};

	const sendErrorMessage = (message: string): void => {
		sendMessage(message, 'error');
	};

	return {
		sendErrorMessage,
		sendMessage,
	};
};
