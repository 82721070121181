import React, { FC } from 'react';
import Paragraph from '../../../../../components/Typography/Paragraph/Paragraph';
import { ParameterValue } from '../../../../../types/graphql/RunHistory';
import useStyles from './TooltipHistoryListStyles';
import TooltipHistoryListProps from './TooltipHistoryProps';

const TooltipHistoryList: FC<TooltipHistoryListProps> = ({ modelRun }) => {
	const {
		emissionScenario,
		modelRunType,
		modelParameters,
		modelParameterSet,
	} = modelRun;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Paragraph className={classes.paragraph}>
				Emission Scenario: {emissionScenario.name}
			</Paragraph>
			<Paragraph className={classes.paragraph}>
				Run Mode: {modelRunType}
			</Paragraph>
			{modelParameterSet && (
				<Paragraph className={classes.paragraph}>
					Parameter Set: {modelParameterSet}
				</Paragraph>
			)}
			{modelParameters && (
				<div>
					<Paragraph className={classes.paragraph}>Parameters:</Paragraph>
					{modelParameters.map((modelParameter: ParameterValue) => {
						return (
							<Paragraph className={classes.paragraph}>
								{modelParameter.name}: {modelParameter.value}
							</Paragraph>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default TooltipHistoryList;
