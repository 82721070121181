import { v4 as uuidv4 } from 'uuid';
import { Line } from 'react-chartjs-2';
import React, { FC } from 'react';
import useStyles, { chartOptions } from './ClimateFutureChartStyles';
import ClimateFutureChartProps from './ClimateFutureChartProps';

const datasetKeyProvider = () => {
	return uuidv4();
};

const ClimateFutureChart: FC<ClimateFutureChartProps> = ({
	labels,
	data,
	modelRunNames,
}) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Line
				datasetKeyProvider={datasetKeyProvider}
				options={chartOptions(modelRunNames)}
				data={{
					labels,
					datasets: data,
				}}
			/>
		</div>
	);
};

export default ClimateFutureChart;
