import { gql } from 'apollo-boost';

const CreateModelRun = gql`
	mutation CreateModelRun($sessionUuid: String!) {
		createModelRun(sessionUuid: $sessionUuid) {
			uuid
			name
		}
	}
`;

const CreatePlotSelection = gql`
	mutation CreatePlotSelection($sessionUuid: String!, $modelRunUuid: String!) {
		createPlotSelection(
			sessionUuid: $sessionUuid
			modelRunUuid: $modelRunUuid
		) {
			uuid
		}
	}
`;

const DeletePlotSelection = gql`
	mutation DeletePlotSelection($sessionUuid: String!, $modelRunUuid: String!) {
		deletePlotSelection(
			sessionUuid: $sessionUuid
			modelRunUuid: $modelRunUuid
		) {
			uuid
		}
	}
`;

const DeleteModelRun = gql`
	mutation DeleteModelRun($modelRunUuid: String!) {
		deleteModelRun(modelRunUuid: $modelRunUuid)
	}
`;

const UpdateModelRun = gql`
	mutation UpdateModelRun($modelRunUuid: String!, $name: String!) {
		updateModelRun(modelRunUuid: $modelRunUuid, name: $name) {
			uuid
		}
	}
`;

export default {
	CreateModelRun,
	CreatePlotSelection,
	DeletePlotSelection,
	DeleteModelRun,
	UpdateModelRun,
};
