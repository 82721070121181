import React from 'react';
import { Typography } from '@material-ui/core';
import { RunSettingModelParameterSet } from '../../../../../../types/graphql/RunSetting';

export const getElementCode = (
	modelParameter: RunSettingModelParameterSet[],
	runType?: string
) => {
	const filteredParametersSets = modelParameter?.filter(
		(modelParameterSet: RunSettingModelParameterSet) => {
			return modelParameterSet.modelRunType === runType;
		}
	);
	const element = filteredParametersSets?.filter(
		(modelParameterSet: RunSettingModelParameterSet) => {
			return modelParameterSet.isSelected;
		}
	);
	return element[0]?.code;
};

export const handleData = (
	modelParameter: RunSettingModelParameterSet[],
	runType?: string
) => {
	const filteredParametersSets = modelParameter?.filter(
		(modelParameterSet: RunSettingModelParameterSet) => {
			return modelParameterSet.modelRunType === runType;
		}
	);

	const preparedData = filteredParametersSets?.map(
		(set: RunSettingModelParameterSet) => {
			return {
				key: set.code,
				text: set.name,
				value: set.code,
				content: (
					<>
						<Typography variant="h3">{set.name}</Typography>
						<p style={{ fontWeight: 400 }}>
							This is a low parameter set containing mid-level assumptions on x
							and y.
						</p>
					</>
				),
			};
		}
	);

	return preparedData;
};
