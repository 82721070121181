import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		marginTop: 20,
		padding: 20,
		borderRadius: '5px',
		backgroundColor: '#fbfbfb',
	},
});

export const chartOptions = (emissionScenarioNames: string[]) => {
	return {
		legend: {
			labels: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				filter(item: any) {
					return emissionScenarioNames.includes(item.text);
				},
			},
			onClick: null,
		},
		elements: {
			line: {
				tension: 0.000001,
			},
			point: {
				radius: 0,
			},
		},
		responsive: true,
		animation: {
			duration: 750,
		},
		scales: {
			xAxes: [
				{
					type: 'time',
					ticks: {
						padding: 10,
					},
					time: {
						format: 'YYYY',
						stepSize: 10,
						min: '2000',
					},
					gridLines: {
						drawTicks: false,
					},
				},
			],
		},
	};
};
