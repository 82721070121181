import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import PanelTemplateProps from './types';
import useStyles from './PanelStyles';

const Panel: FC<PanelTemplateProps> = (props) => {
	const { backgroundColor, component: Component } = props;

	const classes = useStyles({ backgroundColor });
	return (
		<div className={classes.root}>
			<div className={classes.column}>
				<Grid className={classes.grid} container>
					<Component {...props} />
				</Grid>
			</div>
		</div>
	);
};

export default Panel;
