import React, { FC } from 'react';
import { getPoints } from '../helpers';
import { PolygonProps } from './PolygonProps';

const Polygon: FC<PolygonProps> = (props) => {
	const { data, style, scale } = props;
	const points = getPoints(data, scale);

	return <polygon points={points} style={style} />;
};

export default Polygon;
