import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../theming/colors';

export default makeStyles({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		marginTop: '40px',
		background: 'linear-gradient(289.33deg, #006663 13.57%, #005E72 86.79%)',
		backgroundBlendMode: 'normal',
		borderRadius: '5px',
		color: 'white',
		height: '57px',
		width: '239px',
		alignSelf: 'center',
	},
	paragraphText: {
		color: 'white',
		fontSize: '17px',
	},
	disabledButton: {
		background: colors.secondary,
	},
	primaryIcon: {
		color: '#fff',
	},
});
