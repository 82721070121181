import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import logo from '../../../../assets/live-magicc-logo.png';
import useStyles from './LogoStyles';

const Logo: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<img alt="logo" className={classes.img} src={logo} />
			<div className={classes.titleWrapper}>
				<Typography variant="h1" className={classes.title}>
					{t('domain:header.title')}
				</Typography>
				<Typography variant="h2" className={classes.subtitle}>
					{t('domain:header.subtitle')}
				</Typography>
			</div>
		</div>
	);
};

export default Logo;
