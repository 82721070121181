import { IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import useGraphQLMutation from '../../../../../services/hooks/useGraphQLMutation';
import mutations from '../../apollo/mutations';
import queries from '../../apollo/queries';
import DeleteButtonProps from './DeleteButtonProps';

const DeleteButton: FC<DeleteButtonProps> = ({ modelRunUuid, sessionUuid }) => {
	const [deleteModelRun] = useGraphQLMutation(mutations.DeleteModelRun, {
		refetchQueries: [
			{
				query: queries.runHistory,
				variables: {
					sessionUuid,
				},
			},
		],
	});
	return (
		<IconButton
			onClick={() =>
				deleteModelRun({
					variables: {
						modelRunUuid,
					},
				})
			}
			title="delete"
			color="primary"
			edge="end"
			aria-label="delete"
		>
			<DeleteIcon />
		</IconButton>
	);
};

export default DeleteButton;
