import { makeStyles } from '@material-ui/core';
import { colors } from '../../../theming/colors';

export default makeStyles({
	container: {
		position: 'fixed',
		bottom: '200px',
		'@media only screen and (max-width: 600px)': {
			bottom: '190px',
		},
		zIndex: 300,
	},
	buttonStyle: {
		padding: '22px',
		'@media only screen and (max-width: 600px)': {
			padding: '12px',
		},
		position: 'fixed',
		bottom: '200px',
		left: '-6px',
		backgroundColor: '#fff',
		borderRadius: '0 20px 20px 0',
		boxShadow: '2px 2px 8px grey',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
	buttonScenarios: {
		bottom: '199px',
	},
	buttonPlay: {
		bottom: '300px',
		color: colors.primary,
		'&:hover': {
			backgroundColor: colors.secondary,
			'@media (hover: none)': {
				'&:disabled': {
					backgroundColor: colors.secondary,
				},
			},
		},
		'&:disabled': {
			backgroundColor: colors.secondary,
		},
	},
	disabledButton: {
		backgroundColor: colors.secondary,
	},
	image: { width: '30px', height: '30px' },
});
