import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import EmptyStatePanel from '../../../../components/Page/EmptyStatePanel/EmptyStatePanel';
import PanelTemplateProps from '../../../../components/Page/Panel/types';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import { PROBABILISTIC_TYPE } from '../../../../config/constants/modelTypes';
import { ModelRun } from '../../../../types/graphql/RunHistory';
import TemperatureGraph from './components/TemperatureGraph/TemperatureGraph';
import useStyles from './PanelTemperatureStyles';

const PanelTemperature: FC<PanelTemplateProps> = ({ activeModelRuns }) => {
	const [showEmptyState, setShowEmptyState] = useState<boolean>(true);
	const [probabilisticActiveRuns, setProbabilisticActiveRuns] = useState<
		ModelRun[]
	>([]);

	useEffect(() => {
		if (activeModelRuns) {
			const probabilisticRuns = activeModelRuns?.filter(
				(modelRun: ModelRun) => modelRun.modelRunType === PROBABILISTIC_TYPE
			);
			if (probabilisticRuns.length === 0) {
				setShowEmptyState(true);
			} else {
				setProbabilisticActiveRuns(probabilisticRuns);
				setShowEmptyState(false);
			}
		}
	}, [activeModelRuns]);

	const classes = useStyles();

	if (showEmptyState || !activeModelRuns) {
		return <EmptyStatePanel panel="temperature" classes={classes} />;
	}

	return (
		<>
			<Grid item xs={12} sm={12} md={12}>
				<PanelHeader>Peak Temperature</PanelHeader>
				<Paragraph className={classes.paragraph}>
					Those are the peak years and the temperature bands (37%, 50% and 73%
					uncertainty) of the selected scenarios
				</Paragraph>
				<TemperatureGraph probabilisticActiveRuns={probabilisticActiveRuns} />
			</Grid>
		</>
	);
};

export default PanelTemperature;
