import { makeStyles } from '@material-ui/core';

export default makeStyles({
	tabs: {
		maxWidth: '1200px',
		margin: 'auto',
		'@media only screen and (max-width: 600px)': {
			top: '98px',
		},
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		backgroundColor: '#fff',
		'@media only screen and (max-width: 768px)': {
			width: '100%',
		},
	},
});
