import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 400,
		color: '#372d38',
	},
	img: {
		height: '100px',
	},
	titleWrapper: {
		marginLeft: '12px',
		marginTop: -8,
	},
	title: {
		fontSize: '46px',
		marginBottom: 0,
	},
	subtitle: {
		fontSize: '12px',
		margin: 0,
	},
});
