import React from 'react';

const EmptyGraphUncertainty = () => {
	return (
		<svg
			className="svg-graph"
			width="463"
			height="139"
			viewBox="0 0 463 139"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.995"
				d="M454.244 0H8.75651C3.92043 0 0 3.92043 0 8.75651V129.888C0 134.724 3.92043 138.645 8.75651 138.645H454.244C459.08 138.645 463 134.724 463 129.888V8.75651C463 3.92043 459.08 0 454.244 0Z"
				fill="#F8F8F8"
			/>
			<path
				opacity="0.995"
				d="M22.8477 105.842H142.016C114.516 98.9235 100.801 33.791 81.9227 33.0142C65.3764 32.3332 41.9452 103.657 22.8477 105.842Z"
				fill="#CBCBCB"
				stroke="#A5A5A5"
				strokeWidth="0.999997"
			/>
			<path
				opacity="0.995"
				d="M208.602 105.858H337.056C307.412 98.9365 292.629 33.7755 272.28 32.9983C254.444 32.3171 229.188 103.672 208.602 105.858Z"
				fill="#FFA246"
				fillOpacity="0.181467"
				stroke="#FF9721"
			/>
			<path
				opacity="0.995"
				d="M251.654 105.876H391.455C359.194 98.9506 343.104 33.7586 320.957 32.981C301.546 32.2995 274.058 103.689 251.654 105.876Z"
				fill="#F88A98"
				fillOpacity="0.467182"
				stroke="#EC0D27"
				strokeWidth="0.999998"
			/>
			<path
				d="M16.9146 105.917H444.754"
				stroke="#BBBBBB"
				strokeWidth="1.01061"
			/>
		</svg>
	);
};

export default EmptyGraphUncertainty;
