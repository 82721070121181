import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		width: '350px',
		backgroundColor: '#fff',
		boxShadow: '2px 2px 8px grey',
		borderRadius: '0 20px 20px 0',
	},
	header: {
		padding: '12px 12px 0 !important',
		lineHeight: '2em',
	},
	list: {
		maxHeight: '400px',
		'@media only screen and (max-width: 600px)': {
			maxHeight: '200px',
		},
		overflowY: 'auto',
		borderTop: '1px solid grey',
	},
	image: { width: '30px', height: '30px', marginBottom: '5px' },
	openStatusButtonScenarios: {
		backgroundColor: 'transparent',
		boxShadow: 'none !important',
		margin: '10px 0',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
	buttonLabel: {
		width: '100%',
		display: 'inherit',
		alignItems: 'inherit',
		justifyContent: 'flex-start',
	},
});
