import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../../theming/colors';

export default makeStyles({
	gridContainer: {
		margin: 'auto',
		padding: '50px',
		'@media (max-width: 768px)': {
			padding: '50px 0',
		},
	},
	row: {
		display: 'flex',
	},
	runMode: {
		marginBottom: '30px',
	},
	runModeText: {
		fontSize: '16px',
		color: colors.secondaryText,
	},
	radioContainer: {
		display: 'flex',
		marginBottom: '15px',
		alignItems: 'center',
	},
	radioLabel: {
		fontSize: '18px !important',
	},
	radioLabelSelected: {
		fontSize: '18px',
		marginLeft: '8px',
		fontWeight: 'bold',
	},
});
