import { FormControlLabel, Switch } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../../../components/Loading/Loading';
import { useAuthentication } from '../../../../../../../services/useAuthentication';
import useGraphQLMutation from '../../../../../../../services/hooks/useGraphQLMutation';
import mutations from '../../../apollo/mutations';
import SingleAdvancedToggleProps from './SingleAdvancedToggleProps';

const SingleAdvancedToggle: FC<SingleAdvancedToggleProps> = ({
	loading,
	isAdvancedMode,
	setIsAdvancedMode,
	setIsAltered,
}) => {
	const { t } = useTranslation();
	const { sessionUuid } = useAuthentication();

	const [updateAdvModelStatus] = useGraphQLMutation(
		mutations.updateRunSettingAdvancedModelStatus,
		{
			onCompleted: (res) => {
				setIsAdvancedMode(res.updateRunSetting.isAdvancedModelSetting);
				setIsAltered(true);
			},
		}
	);

	return (
		<Loading loading={loading}>
			<FormControlLabel
				labelPlacement="start"
				control={
					<Switch
						checked={isAdvancedMode}
						onChange={() =>
							updateAdvModelStatus({
								variables: {
									sessionUuid,
									isAdvancedModelSetting: !isAdvancedMode,
								},
							})
						}
						name="advanced"
						color="primary"
					/>
				}
				label={
					<p style={{ fontFamily: 'Commissioner' }}>
						{t('domain:model-settings.advanced')}
					</p>
				}
			/>
		</Loading>
	);
};

export default SingleAdvancedToggle;
