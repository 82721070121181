import {
	Button,
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	OutlinedInput,
	Tooltip,
} from '@material-ui/core';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import Loading from '../../../../../components/Loading/Loading';
import Paragraph from '../../../../../components/Typography/Paragraph/Paragraph';
import DeleteButton from '../DeleteButton/DeleteButton';
import TooltipHistoryList from '../TooltipHistoryList/TooltipHistoryList';
import ModelRunListItemProps from './ModelRunListItemProps';
import useStyles from './ModelRunListItemStyles';
import useGraphQLMutation from '../../../../../services/hooks/useGraphQLMutation';
import mutations from '../../apollo/mutations';
import queries from '../../../apollo/queries';

const ModelRunListItem: FC<ModelRunListItemProps> = ({
	data,
	sessionUuid,
	length,
	handlePlotSelection,
	selectedVariable,
	windowCenter,
}) => {
	const [isEditable, setIsEditable] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');

	const [updateModelRun] = useGraphQLMutation(mutations.UpdateModelRun, {
		refetchQueries: [
			{
				query: queries.runHistory,
				variables: {
					sessionUuid,
					variableCode: selectedVariable,
					centerYear: Number(windowCenter),
				},
			},
		],
	});

	const { isActive } = data;

	const classes = useStyles({ isActive });

	useEffect(() => {
		setInputValue(data.name);
	}, []);

	const handleUpdate = () => {
		if (inputValue.length > 1) {
			if (inputValue !== data.name) {
				updateModelRun({
					variables: {
						modelRunUuid: data.uuid,
						name: inputValue,
					},
				});
			}

			setIsEditable(false);
		}
	};

	return (
		<Tooltip
			arrow
			title={<TooltipHistoryList modelRun={data} />}
			placement="right"
			classes={{
				tooltip: classes.tooltip,
				arrow: classes.arrowTooltip,
			}}
		>
			<ListItem className={isActive ? classes.activeItem : classes.item}>
				<div className={classes.itemContent}>
					<Button
						className={classes.button}
						disableRipple={isEditable}
						onClick={() => {
							if (!isEditable) {
								handlePlotSelection(isActive, data.uuid);
							}
						}}
					>
						{isEditable ? (
							<OutlinedInput
								onKeyPress={(event) => {
									if (event.key === 'Enter') {
										handleUpdate();
									}
								}}
								required
								classes={{
									input: classes.input,
								}}
								error={inputValue.length < 1 || inputValue.length > 30}
								inputProps={{
									maxLength: 30,
									minLength: 1,
								}}
								className={classes.outlineRoot}
								value={inputValue}
								onChange={(
									e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
								) => setInputValue(e.target.value)}
							/>
						) : (
							<Paragraph className={classes.paragraph}>{inputValue}</Paragraph>
						)}
					</Button>

					<ListItemSecondaryAction className={classes.actionContainer}>
						<Loading size={20} loading={data.status === 'requested'}>
							{data.status === 'success' ? (
								<>
									{isEditable ? (
										<IconButton
											title="save"
											color="primary"
											edge="end"
											aria-label="save"
											onClick={() => {
												handleUpdate();
											}}
										>
											<CheckIcon />
										</IconButton>
									) : (
										<IconButton
											title="edit"
											color="primary"
											edge="end"
											aria-label="edit"
											onClick={() => setIsEditable(true)}
										>
											<EditIcon />
										</IconButton>
									)}

									{length > 1 && (
										<DeleteButton
											modelRunUuid={data.uuid}
											sessionUuid={sessionUuid}
										/>
									)}
								</>
							) : (
								<>
									<IconButton
										title="error"
										color="primary"
										edge="end"
										aria-label="error"
									>
										<ErrorIcon />
									</IconButton>
									{length > 1 && (
										<DeleteButton
											modelRunUuid={data.uuid}
											sessionUuid={sessionUuid}
										/>
									)}
								</>
							)}
						</Loading>
					</ListItemSecondaryAction>
				</div>
			</ListItem>
		</Tooltip>
	);
};

export default ModelRunListItem;
