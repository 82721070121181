import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FC } from 'react';
import Loading from '../../../../../components/Loading/Loading';
import { EmissionVariable } from '../../../../../types/graphql/EmissionVariables';
import { EmissionVariablesDropdownProps } from './EmissionVariablesDropdownProps';
import useStyles from './EmissionVariablesDropdownStyles';

const EmissionVariablesDropdown: FC<EmissionVariablesDropdownProps> = ({
	emissionVariables,
	variable,
	setVariable,
	loading,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (event: any) => {
		setVariable(event.target.value);
	};

	const classes = useStyles();
	return (
		<Loading loading={loading}>
			<FormControl variant="filled" className={classes.root}>
				<InputLabel id="variable-label">Plotted emission variable</InputLabel>
				<Select
					labelId="variable-label"
					id="variable"
					value={variable}
					onChange={handleChange}
					label="Variable"
				>
					{emissionVariables &&
						emissionVariables.map((emissionVariable: EmissionVariable) => {
							return (
								<MenuItem
									key={emissionVariable.name}
									value={emissionVariable.code}
								>
									{emissionVariable.name}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		</Loading>
	);
};

export default EmissionVariablesDropdown;
