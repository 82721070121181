import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../theming/colors';

export default makeStyles({
	listContainer: {
		height: '300px',
		backgroundColor: '#fff',
		width: '260px',
		margin: 'auto',
		marginTop: '60px',
		marginBottom: '50px',
		overflowY: 'auto',
		borderRadius: '5px',
	},
	listItem: {
		cursor: 'pointer',
		padding: '2px',
	},
	badge: {
		height: '35px',
		width: '5px',
		marginRight: '10px',
		borderRadius: '5px',
	},
	paragraph: {
		color: '#fff',
		fontSize: '11px',
	},
	tooltip: {
		backgroundColor: colors.secondaryText,
	},
	arrowTooltip: {
		color: colors.secondaryText,
	},
});
