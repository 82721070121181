/* eslint-disable no-param-reassign */
import { Button, Modal } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import history from '../../../assets/history.png';
import ModelRunsList from './ModelRunsList/ModelRunsList';
import useStyles from './ModelRunsStyle';
import { useAuthentication } from '../../../services/useAuthentication';
import { ModelRun, RunHistory } from '../../../types/graphql/RunHistory';
import useGraphQLLazyQuery from '../../../services/hooks/useGraphQLLazyQuery';
import ModelRunsProps from './ModelRunsProps';
import queries from '../apollo/queries';

const ModelRunHistory: FC<ModelRunsProps> = ({
	selectedVariable,
	windowCenter,
}) => {
	const [modelRunHistoryData, setModelRunHistoryData] = useState<ModelRun[]>(
		[]
	);
	const [showHistoryButton, setShowHistoryButton] = useState(false);
	const [open, setOpen] = useState(false);
	const { sessionUuid } = useAuthentication();
	const classes = useStyles();

	const [fetchRunHistory, { data }] = useGraphQLLazyQuery(queries.runHistory, {
		variables: {
			sessionUuid,
			variableCode: selectedVariable,
			centerYear: Number(windowCenter),
		},
		fetchPolicy: 'cache-first',
	});

	useEffect(() => {
		if (data) {
			handleResponse(data.runHistory);
			window.location.hash = data.runHistory.hash;
		}
	}, [data]);

	const handleResponse = (runHistory: RunHistory) => {
		const { modelRuns } = runHistory;
		const modelRunsStatusFromHistoryData = modelRunHistoryData.map(
			(modelRun: ModelRun) => {
				return {
					uuid: modelRun.uuid,
					status: modelRun.status,
					isActive: modelRun.isActive,
				};
			}
		);

		const modelRunsStatus = modelRuns.map((modelRun: ModelRun) => {
			return {
				uuid: modelRun.uuid,
				status: modelRun.status,
				isActive: modelRun.isActive,
			};
		});

		if (
			JSON.stringify(modelRunsStatus) !==
			JSON.stringify(modelRunsStatusFromHistoryData)
		) {
			setModelRunHistoryData(modelRuns);
			if (modelRuns.length > 1) {
				setOpen(true);
			}
		}

		if (modelRuns.length <= 1) {
			setShowHistoryButton(false);
		} else if (!showHistoryButton) {
			setShowHistoryButton(true);
			setOpen(true);
		}
	};

	useEffect(() => {
		fetchRunHistory();
	}, []);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const interval = setInterval(() => {
			fetchRunHistory();
		}, 3000);
		if (modelRunHistoryData) {
			const requestedStatusElement = modelRunHistoryData.find(
				(modelRun: ModelRun) => modelRun.status === 'requested'
			);
			if (!requestedStatusElement) {
				clearInterval(interval);
			}
		}
		return () => clearInterval(interval);
	}, [modelRunHistoryData]);

	return (
		<div className={classes.container}>
			{open && (
				<Modal
					open={open}
					disableScrollLock
					onClose={() => setOpen(!open)}
					BackdropProps={{
						invisible: true,
					}}
					style={{
						inset: 'auto 0 200px',
					}}
				>
					<ModelRunsList
						selectedVariable={selectedVariable}
						setOpen={setOpen}
						open={open}
						modelRunHistoryData={modelRunHistoryData}
						windowCenter={windowCenter}
					/>
				</Modal>
			)}
			{showHistoryButton && !open && (
				<Button
					tabIndex={0}
					variant="contained"
					className={clsx(classes.buttonStyle, classes.buttonScenarios)}
					onClick={() => {
						setOpen(!open);
					}}
				>
					<img alt="modelrun" className={classes.image} src={history} />
				</Button>
			)}
		</div>
	);
};

export default ModelRunHistory;
