import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import useStyles from './StartRunButtonStyles';
import useGraphQLMutation from '../../../../services/hooks/useGraphQLMutation';
import mutations from '../../Panels/EmissionScenarios/apollo/mutations';
import { useAuthentication } from '../../../../services/useAuthentication';
import queries from '../../ModelRunHistory/apollo/queries';
import StartRunButtonProps from './StartRunButtonProps';
import Loading from '../../../../components/Loading/Loading';

const StartRunButton: FC<StartRunButtonProps> = ({
	isAltered,
	setIsAltered,
}) => {
	const { sessionUuid } = useAuthentication();
	const { t } = useTranslation();
	const [createModelRun, { loading }] = useGraphQLMutation(
		mutations.CreateModelRun,
		{
			variables: {
				sessionUuid,
			},
			refetchQueries: [
				{
					query: queries.runHistory,
					variables: {
						sessionUuid,
					},
				},
			],
			onCompleted: () => setIsAltered(false),
		}
	);

	const disabled = loading || !isAltered;

	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Button
				disabled={disabled}
				variant="contained"
				className={classes.button}
				startIcon={
					!loading && (
						<PlayArrowIcon
							color="primary"
							classes={{
								colorPrimary: classes.primaryIcon,
							}}
						/>
					)
				}
				onClick={() => createModelRun()}
				classes={{
					disabled: classes.disabledButton,
				}}
			>
				<Loading loading={loading}>
					<Paragraph className={classes.paragraphText}>
						{t('domain:model-run.button.label')}
					</Paragraph>
				</Loading>
			</Button>
		</div>
	);
};

export default StartRunButton;
