import { gql } from 'apollo-boost';

const runSettingRunTypes = gql`
	query runSettingRunTypes($sessionUuid: String!) {
		runSetting(sessionUuid: $sessionUuid) {
			uuid
			isAdvancedModelSetting
			modelRunTypes {
				uuid
				code
				name
				isSelected
			}
		}
	}
`;

const runSettingParameterSets = gql`
	query runSettingParameterSets($sessionUuid: String!) {
		runSetting(sessionUuid: $sessionUuid) {
			uuid
			modelParameterSets {
				uuid
				code
				name
				modelRunType
				isSelected
			}
		}
	}
`;

const runSettingModelParameters = gql`
	query runSettingModelParameters($sessionUuid: String!) {
		runSetting(sessionUuid: $sessionUuid) {
			uuid
			modelParameters {
				uuid
				code
				name
				value
				min
				max
				group
			}
		}
	}
`;

export default {
	runSettingRunTypes,
	runSettingParameterSets,
	runSettingModelParameters,
};
