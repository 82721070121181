import React, { FC } from 'react';
import List from '@material-ui/core/List';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './ModelRunsListStyles';
import { ModelRun } from '../../../../types/graphql/RunHistory';
import ModelRunListProps from './ModelRunsListProp';
import history from '../../../../assets/history.png';
import useGraphQLMutation from '../../../../services/hooks/useGraphQLMutation';
import mutations from '../apollo/mutations';
import { useAuthentication } from '../../../../services/useAuthentication';
import ModelRunListItem from '../components/ModelRunListItem/ModelRunListItem';
import queries from '../../apollo/queries';

const ModelRunsList: FC<ModelRunListProps> = ({
	modelRunHistoryData,
	open,
	setOpen,
	selectedVariable,
	windowCenter,
}) => {
	const classes = useStyles();
	const { sessionUuid } = useAuthentication();

	const [createPlotSelection] = useGraphQLMutation(
		mutations.CreatePlotSelection,
		{
			onCompleted: (res) => {
				window.location.hash = res.createPlotSelection.uuid;
			},
			refetchQueries: [
				{
					query: queries.runHistory,
					variables: {
						sessionUuid,
						variableCode: selectedVariable,
						centerYear: Number(windowCenter),
					},
				},
			],
		}
	);

	const [deletePlotSelection] = useGraphQLMutation(
		mutations.DeletePlotSelection,
		{
			onCompleted: (res) => {
				window.location.hash = res.createPlotSelection.uuid;
			},
			refetchQueries: [
				{
					query: queries.runHistory,
					variables: {
						sessionUuid,
						variableCode: selectedVariable,
						centerYear: Number(windowCenter),
					},
				},
			],
		}
	);

	const handlePlotSelection = (isActive: boolean, modelRunUuid: string) => {
		if (isActive) {
			deletePlotSelection({
				variables: {
					modelRunUuid,
					sessionUuid,
				},
			});
		} else {
			createPlotSelection({
				variables: {
					modelRunUuid,
					sessionUuid,
				},
			});
		}
	};

	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Typography className={classes.header} variant="h3">
				{t('domain:run-history.title')}
			</Typography>
			<List className={classes.list}>
				{modelRunHistoryData &&
					modelRunHistoryData?.map((data: ModelRun) => {
						return (
							<ModelRunListItem
								handlePlotSelection={handlePlotSelection}
								key={data.uuid}
								data={data}
								sessionUuid={sessionUuid}
								length={modelRunHistoryData.length}
								selectedVariable={selectedVariable}
								windowCenter={windowCenter}
							/>
						);
					})}
			</List>
			<Button
				variant="contained"
				classes={{
					label: classes.buttonLabel,
				}}
				className={classes.openStatusButtonScenarios}
				onClick={() => {
					setOpen(!open);
				}}
			>
				<img alt="modelrun" className={classes.image} src={history} />
			</Button>
		</div>
	);
};

export default ModelRunsList;
