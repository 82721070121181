import { gql } from 'apollo-boost';

const runHistory = gql`
	query runHistory(
		$sessionUuid: String!
		$variableCode: String!
		$centerYear: Int!
	) {
		runHistory(sessionUuid: $sessionUuid) {
			uuid
			hash
			modelRuns {
				name
				uuid
				status
				isActive
				modelRunType
				emissionScenario {
					code
					name
					uuid
				}
				data {
					methods
					climateFuture(variableCode: $variableCode) {
						value
						year
					}
					density(centerYear: $centerYear) {
						x
						y
					}
					peakYears {
						p
						x
						y
					}
				}
			}
		}
	}
`;

export default {
	runHistory,
};
