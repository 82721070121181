import { makeStyles } from '@material-ui/core';
import { colors } from '../../../theming/colors';

export default makeStyles({
	footerRoot: {
		minHeight: '250px',
		backgroundColor: colors.darkBackground,
		alignSelf: 'center',
		paddingBottom: '10px',
	},
	footerGrid: {
		justifyContent: 'space-between',
		padding: '50px 100px',
	},
	bottomText: {
		color: colors.secondary,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '12px',
	},
	image: {
		marginRight: '15px !important',
		height: '15px',
		width: 'auto',
		borderRadius: '0 !important',
	},
	gridRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		'@media only screen and (max-width: 768px)': {
			justifyContent: 'center',
		},
	},
	heartIcon: {
		padding: '2px',
	},
});
