import { makeStyles } from '@material-ui/core';

export default makeStyles({
	dropdownContainer: {
		margin: '80px 50px',
		fontSize: '1.1em',
		textAlign: 'center',
		alignSelf: 'center',
	},
	dropdown: {
		width: '100%',
	},
	chartRoot: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 30,
		padding: 30,
		'@media only screen and (max-width: 1025px)': {
			padding: '60px 0',
			margin: 0,
		},
		'@media (max-width: 768px)': {
			padding: 0,
		},
	},
});
