import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../Typography/Paragraph/Paragraph';
import useStyles from './TeaserStyles';

const Teaser: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.root}>
			<div>
				<div className={classes.itemContainer}>
					<Paragraph className={classes.itemNumber}>1</Paragraph>
					<Paragraph className={classes.itemText}>
						{t('domain:header.first-step')}
					</Paragraph>
				</div>
				<div className={classes.itemContainer}>
					<Paragraph className={classes.itemNumber}>2</Paragraph>
					<Paragraph className={classes.itemText}>
						{t('domain:header.second-step')}
					</Paragraph>
				</div>
				<div className={classes.itemContainer}>
					<Paragraph className={classes.itemNumber}>3</Paragraph>
					<Paragraph className={classes.itemText}>
						{t('domain:header.third-step')}
					</Paragraph>
				</div>
			</div>
		</div>
	);
};

export default Teaser;
