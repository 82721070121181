import { ModelRunDataPeakYears } from '../../../../types/graphql/RunHistory';

export const handlePeakYearData = (peakYear: ModelRunDataPeakYears[]) => {
	const ySpan = 0.5;

	const yValue = peakYear[0].y;
	const minY = yValue - ySpan;
	const maxY = yValue + ySpan;
	const fiveData = peakYear.find((el) => el.p === 5);
	const twentyFiveData = peakYear.find((el) => el.p === 25);
	const fiftyData = peakYear.find((el) => el.p === 50);
	const seventyFiveData = peakYear.find((el) => el.p === 75);
	const ninetyFiveData = peakYear.find((el) => el.p === 95);

	if (
		fiveData &&
		twentyFiveData &&
		fiftyData &&
		seventyFiveData &&
		ninetyFiveData
	) {
		return [
			[
				{
					x: fiveData.x,
					y: minY,
				},
				{
					x: fiveData.x,
					y: maxY,
				},
				{
					x: twentyFiveData.x,
					y: maxY,
				},
				{
					x: twentyFiveData.x,
					y: minY,
				},
			],
			[
				{
					x: twentyFiveData.x,
					y: minY,
				},
				{
					x: twentyFiveData.x,
					y: maxY,
				},
				{
					x: fiftyData.x,
					y: maxY,
				},
				{
					x: fiftyData.x,
					y: minY,
				},
			],
			[
				{
					x: fiftyData.x,
					y: minY,
				},
				{
					x: fiftyData.x,
					y: maxY,
				},
				{
					x: seventyFiveData.x,
					y: maxY,
				},
				{
					x: seventyFiveData.x,
					y: minY,
				},
			],
			[
				{
					x: seventyFiveData.x,
					y: minY,
				},
				{
					x: seventyFiveData.x,
					y: maxY,
				},
				{
					x: ninetyFiveData.x,
					y: maxY,
				},
				{
					x: ninetyFiveData.x,
					y: minY,
				},
			],
		];
	}
	return [];
};
