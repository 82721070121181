import { gql } from 'apollo-boost';

const EmissionScenariosGraphData = gql`
	query EmissionScenariosGraphData(
		$variableCode: String!
		$scenariosUuids: [String!]
	) {
		emissionScenarios(uuids: $scenariosUuids) {
			uuid
			code
			name
			data(variableCode: $variableCode) {
				year
				value
			}
		}
	}
`;

export default {
	EmissionScenariosGraphData,
};
