import { makeStyles } from '@material-ui/core';

export default makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '20px',
	},
	label: {
		width: '274px',
		'@media only screen and (max-width: 1279px)': {
			width: '500px',
		},
		'@media only screen and (max-width: 1000)': {
			width: '400px',
		},
		'@media only screen and (max-width: 800px)': {
			width: '300px',
		},
		'@media only screen and (max-width: 700px)': {
			width: '200px',
		},
		'@media only screen and (max-width: 600px)': {
			width: '180px',
		},
	},
});
