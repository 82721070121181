import { gql } from 'apollo-boost';

const updateRunSettingModelRunType = gql`
	mutation updateRunSettingModelRunType(
		$sessionUuid: String!
		$modelRunType: String!
	) {
		updateRunSetting(sessionUuid: $sessionUuid, modelRunType: $modelRunType) {
			uuid
			modelRunTypes {
				uuid
				code
				isSelected
			}
		}
	}
`;

const updateRunSettingAdvancedModelStatus = gql`
	mutation updateRunSettingAdvancedModelStatus(
		$sessionUuid: String!
		$isAdvancedModelSetting: Boolean!
	) {
		updateRunSetting(
			sessionUuid: $sessionUuid
			isAdvancedModelSetting: $isAdvancedModelSetting
		) {
			uuid
			isAdvancedModelSetting
		}
	}
`;

const updateRunSettingParameterSets = gql`
	mutation updateRunSettingParameterSets(
		$sessionUuid: String!
		$parameterSetCode: String!
	) {
		updateRunSetting(sessionUuid: $sessionUuid) {
			uuid
			updateModelParameterSet(code: $parameterSetCode) {
				uuid
				code
				name
				modelRunType
				isSelected
			}
		}
	}
`;

const updateRunSettingModelParameter = gql`
	mutation updateRunSettingModelParameter(
		$sessionUuid: String!
		$parameterUuid: String!
		$parameterValue: Float!
	) {
		updateRunSetting(sessionUuid: $sessionUuid) {
			updateModelParameter(uuid: $parameterUuid, value: $parameterValue) {
				uuid
				code
				name
				value
				min
				max
				group
			}
		}
	}
`;

export default {
	updateRunSettingModelRunType,
	updateRunSettingAdvancedModelStatus,
	updateRunSettingParameterSets,
	updateRunSettingModelParameter,
};
