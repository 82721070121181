/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode } from 'graphql';
import {
	ApolloError,
	MutationHookOptions,
	MutationTuple,
	OperationVariables,
	useMutation,
} from '@apollo/client';
import useApolloErrorHandling from '../useApolloErrorHandling';

export default <TData = any, TVariables = OperationVariables>(
	mutation: DocumentNode,
	options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
	const [handleError] = useApolloErrorHandling();
	return useMutation(mutation, {
		...options,
		awaitRefetchQueries: true,
		onError: (error: ApolloError) => {
			handleError(error);
		},
	});
};
