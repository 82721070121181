import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import useStyles from './EmissionScenariosStyles';
import queries from './apollo/queries';
import EmissionScenariosList from './EmissionScenariosList/EmissionScenariosList';
import EmissionScenarioChart from './EmissionScenarioChart/EmissionScenarioChart';
import EmissionVariablesDropdown from './EmissionVariables/EmissionVariablesDropdown';
import EmissionScenariosProps from './EmissionScenariosProps';
import useGraphQLQuery from '../../../../services/hooks/useGraphQLQuery';
import StartRunButton from '../../ConfigurationTabs/StartRunButton/StartRunButton';
import { defaultVariable } from '../../../../config/constants/fallbackVariable';
import { EmissionVariable } from '../../../../types/graphql/EmissionVariables';

const EmissionScenarios: FC<EmissionScenariosProps> = ({
	selectedScenarios,
	setSelectedScenarios,
	isAltered,
	setIsAltered,
}) => {
	const { t } = useTranslation();
	const { data, loading } = useGraphQLQuery(
		queries.EmissionScenariosAndVariables
	);

	const { emissionScenarios, emissionVariables } = data || {};
	const [variable, setVariable] = useState(defaultVariable);

	const { unit } = data
		? emissionVariables.find((item: EmissionVariable) => item.code === variable)
		: '';

	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={12} md={4}>
				<PanelHeader>{t('domain:emissions.title')}</PanelHeader>
				<Paragraph style={{ maxWidth: 400 }}>
					{t('domain:emissions.main')}
				</Paragraph>

				<EmissionScenariosList
					setIsAltered={setIsAltered}
					selectedScenarios={selectedScenarios}
					setSelectedScenarios={setSelectedScenarios}
					emissionScenarios={emissionScenarios}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<div className={classes.chartRoot}>
					<EmissionVariablesDropdown
						variable={variable}
						setVariable={setVariable}
						emissionVariables={emissionVariables}
						loading={loading}
					/>
					<EmissionScenarioChart
						variable={variable}
						unit={unit}
						selectedScenarios={selectedScenarios}
					/>
				</div>
			</Grid>
			<StartRunButton isAltered={isAltered} setIsAltered={setIsAltered} />
		</>
	);
};

export default EmissionScenarios;
