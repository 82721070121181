import { makeStyles } from '@material-ui/core';

export default makeStyles({
	gridContainer: {
		padding: '50px',
		'@media (max-width: 767px)': {
			padding: 0,
		},
	},
});
