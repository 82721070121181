import i18n from './i18n/configuration';

export const LeftFooterMenuConfig = [
	{ title: i18n.t('common:footer.magicc-api'), path: '/' },
	{ title: i18n.t('common:footer.scientific-reference'), path: '/' },
	{ title: i18n.t('common:footer.the-team'), path: '/' },
];

export const RightFooterMenuConfig = [
	{ title: i18n.t('common:footer.more-links'), path: '/' },
	{ title: i18n.t('common:footer.imprint'), path: '/' },
	{ title: i18n.t('common:footer.data-privacy'), path: '/' },
];
