import { makeStyles } from '@material-ui/core';
import banner from '../../../assets/teaser-background.jpg';

export default makeStyles({
	root: {
		height: '400px',
		'@media only screen and (max-width: 768px)': {
			height: '200px',
		},
		width: '100%',
		backgroundImage: `url(${banner})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center, center',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		'@media only screen and (min-width: 600px)': {
			padding: '30px',
		},
	},
	itemContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 16px',
		margin: '12px 0',
	},
	itemNumber: {
		display: 'inline-block',
		margin: '0 16px',
		color: '#fff',
		width: '30px',
		height: '30px',
		padding: '2px',
		borderRadius: '50%',
		textAlign: 'center',
		fontSize: '16px',
		border: '1px solid #fff',
		textShadow: '2px 2px 8px black',
	},
	itemText: {
		fontSize: '18px',
		color: '#fff',
		textShadow: '2px 2px 8px black',
		margin: 0,
	},
});
