import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import useStyles from './EmissionScenariosListStyles';
import scenariosColors from '../../../../../theming/scenariosColors';
import EmissionScenariosListProps from './EmissionScenariosListProps';
import { EmissionScenario } from '../../../../../types/graphql/EmissionScenario';
import { colors } from '../../../../../theming/colors';
import Paragraph from '../../../../../components/Typography/Paragraph/Paragraph';
import queries from '../apollo/queries';
import mutations from '../apollo/mutations';
import { useAuthentication } from '../../../../../services/useAuthentication';
import useGraphQLQuery from '../../../../../services/hooks/useGraphQLQuery';
import useGraphQLMutation from '../../../../../services/hooks/useGraphQLMutation';

const EmissionScenariosList: FC<EmissionScenariosListProps> = ({
	emissionScenarios,
	selectedScenarios,
	setSelectedScenarios,
	setIsAltered,
}) => {
	const { t } = useTranslation();
	const { sessionUuid } = useAuthentication();

	const handleSelection = (variableUuid: string) => {
		const newSelection = [...selectedScenarios];

		if (
			selectedScenarios.includes(variableUuid) &&
			selectedScenarios.length > 1
		) {
			const index = selectedScenarios.indexOf(variableUuid);
			newSelection.splice(index, 1);
			setSelectedScenarios(newSelection);
			setIsAltered(true);
		} else if (!selectedScenarios.includes(variableUuid)) {
			newSelection.push(variableUuid);
			setSelectedScenarios(newSelection);
			setIsAltered(true);
		}
	};

	const { data } = useGraphQLQuery(queries.RunSettingEmissionScenarios, {
		variables: {
			sessionUuid,
		},
		onCompleted: (response) => {
			const arrayOfUuids = response.runSetting?.emissionScenarios.map(
				(scenario: EmissionScenario) => scenario.uuid
			);
			setSelectedScenarios(arrayOfUuids);
		},
	});

	const { emissionScenarios: userEmissionScenarios } = data?.runSetting || {};

	const isSelected = (emissionUuid: string) => {
		return userEmissionScenarios?.find(
			(el: EmissionScenario) => el.uuid === emissionUuid
		);
	};

	const [updateUserEmissionScenarios] = useGraphQLMutation(
		mutations.UpdateRunSettingEmissionScenarios
	);

	useEffect(() => {
		if (data) {
			updateUserEmissionScenarios({
				variables: {
					sessionUuid,
					emissionScenarios: selectedScenarios,
				},
				refetchQueries: [
					{
						query: queries.RunSettingEmissionScenarios,
						variables: {
							sessionUuid,
						},
					},
				],
			});
		}
	}, [selectedScenarios]);

	const classes = useStyles();
	return (
		<div className={classes.listContainer} id="list-container">
			<List disablePadding>
				{emissionScenarios &&
					emissionScenarios.map((emissionScenario: EmissionScenario) => {
						return (
							<div key={emissionScenario.uuid}>
								<ListItem
									className={classes.listItem}
									key={emissionScenario.code}
									onClick={() => handleSelection(emissionScenario.uuid)}
								>
									<div
										style={{
											backgroundColor: isSelected(emissionScenario.uuid)
												? scenariosColors[emissionScenario.code]
												: colors.secondary,
										}}
										className={classes.badge}
									/>
									<Paragraph style={{ fontSize: '13px' }}>
										{emissionScenario.name}
									</Paragraph>
									<ListItemSecondaryAction>
										<Tooltip
											title={
												<Paragraph className={classes.paragraph}>
													{t(
														`domain:emissions.emission-list.${emissionScenario.code}`
													)}
												</Paragraph>
											}
											placement="right"
											arrow
											classes={{
												tooltip: classes.tooltip,
												arrow: classes.arrowTooltip,
											}}
										>
											<IconButton edge="end">
												<InfoIcon
													style={{ color: '#b1d3ed', fontSize: '18px' }}
												/>
											</IconButton>
										</Tooltip>
									</ListItemSecondaryAction>
								</ListItem>
								<Divider component="li" />
							</div>
						);
					})}
			</List>
		</div>
	);
};

export default EmissionScenariosList;
