import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PanelHeader from '../../../../components/Page/Panel/PanelHeader';
import Paragraph from '../../../../components/Typography/Paragraph/Paragraph';
import ModelRunTypeSwitch from './components/ModelRunTypeSwitch/ModelRunTypeSwitch';
import useStyles from './ModelSettingsStyles';
import queries from './apollo/queries';
import { SINGLE_TYPE } from '../../../../config/constants/modelTypes';
import { useAuthentication } from '../../../../services/useAuthentication';
import SingleAdvancedToggle from './Single/Advanced/SingleAdvancedToggle/SingleAdvancedToggle';
import SingleSettings from './Single/SingleSettings';
import ProbabilisticSettings from './Probabilistic/ProbabilisticSettings';
import { ModelRunTypesCode } from './types';
import useGraphQLQuery from '../../../../services/hooks/useGraphQLQuery';
import StartRunButton from '../../ConfigurationTabs/StartRunButton/StartRunButton';
import ModelSettingsProps from './ModelSettingsProps';

const ModelSettings: FC<ModelSettingsProps> = ({ isAltered, setIsAltered }) => {
	const [isSingleAdvancedMode, setIsSingleAdvancedMode] = useState(false);
	const [selectedType, setSelectedType] = useState<ModelRunTypesCode>();
	const { sessionUuid } = useAuthentication();
	const { t } = useTranslation();

	const { data, loading } = useGraphQLQuery(queries.runSettingRunTypes, {
		variables: {
			sessionUuid,
		},
		onCompleted: (res) =>
			setIsSingleAdvancedMode(res.runSetting.isAdvancedModelSetting),
	});

	const { modelRunTypes } = data?.runSetting || {};

	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={12} md={12}>
				<div className={classes.rowContainer}>
					<div className={classes.left}>
						<PanelHeader>{t('domain:model-settings.title')}</PanelHeader>

						<Paragraph style={{ maxWidth: 400 }}>
							{t('domain:model-settings.description')}
						</Paragraph>
					</div>
					<div className={classes.right}>
						{selectedType === SINGLE_TYPE && (
							<SingleAdvancedToggle
								setIsAltered={setIsAltered}
								loading={loading}
								isAdvancedMode={isSingleAdvancedMode}
								setIsAdvancedMode={setIsSingleAdvancedMode}
							/>
						)}
					</div>
				</div>

				<ModelRunTypeSwitch
					setIsAltered={setIsAltered}
					modelRunTypes={modelRunTypes}
					isSingleAdvancedMode={isSingleAdvancedMode}
					selectedType={selectedType}
					setSelectedType={setSelectedType}
				/>

				{selectedType === SINGLE_TYPE ? (
					<SingleSettings
						setIsAltered={setIsAltered}
						selectedType={selectedType}
						isSingleAdvancedMode={isSingleAdvancedMode}
					/>
				) : (
					<ProbabilisticSettings
						setIsAltered={setIsAltered}
						selectedType={selectedType}
					/>
				)}
			</Grid>
			<StartRunButton isAltered={isAltered} setIsAltered={setIsAltered} />
		</>
	);
};

export default ModelSettings;
