import React, { FC, useEffect } from 'react';
import { Radio } from 'semantic-ui-react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../../../../../components/Typography/Paragraph/Paragraph';
import ModelRunTypeSwitchProps from './ModelRunTypeSwitchProps';
import useStyles from './ModelRunTypeSwitchStyles';
import { RunSettingModelRunType } from '../../../../../../types/graphql/RunSetting';
import mutations from '../../apollo/mutations';
import { useAuthentication } from '../../../../../../services/useAuthentication';
import queries from '../../apollo/queries';
import { ModelRunTypesCode } from '../../types';
import { SINGLE_TYPE } from '../../../../../../config/constants/modelTypes';
import useGraphQLMutation from '../../../../../../services/hooks/useGraphQLMutation';

const ModelRunTypeSwitch: FC<ModelRunTypeSwitchProps> = ({
	modelRunTypes,
	isSingleAdvancedMode,
	selectedType,
	setSelectedType,
	setIsAltered,
}) => {
	const { t } = useTranslation();

	const { sessionUuid } = useAuthentication();

	const [updateModelRunType] = useGraphQLMutation(
		mutations.updateRunSettingModelRunType,
		{
			onCompleted: (res) => {
				updateSelectedType(res.updateRunSetting.modelRunTypes);
				setIsAltered(true);
			},
			refetchQueries: [
				{
					query: queries.runSettingRunTypes,
					variables: {
						sessionUuid,
					},
				},
			],
		}
	);

	useEffect(() => {
		if (modelRunTypes) {
			updateSelectedType(modelRunTypes);
		}
	}, [modelRunTypes]);

	const handleChange = (modelRunTypeCode: string) => {
		updateModelRunType({
			variables: {
				sessionUuid,
				modelRunType: modelRunTypeCode,
			},
		});
	};

	const updateSelectedType = (
		modelRunTypesResponse: RunSettingModelRunType[]
	) => {
		const modelSelectedTypeCode = modelRunTypesResponse?.reduce(
			(acc: string, val: RunSettingModelRunType) => {
				if (val.isSelected) {
					// eslint-disable-next-line no-param-reassign
					acc = val.code;
				}
				return acc;
			},
			''
		);

		setSelectedType(modelSelectedTypeCode as ModelRunTypesCode);
	};

	const classes = useStyles();

	if (isSingleAdvancedMode) {
		return null;
	}

	return (
		<Grid className={classes.gridContainer} container>
			<Grid className={classes.row} container>
				<Grid item xs={12} md={3} className={classes.runMode}>
					<p className={classes.runModeText}>
						{t('domain:model-settings.run-mode')}
					</p>
				</Grid>
				<Grid xs={12} md={9} spacing={9} item container>
					{modelRunTypes &&
						modelRunTypes.map((runType: RunSettingModelRunType) => {
							const { name, code } = runType;
							return (
								<Grid item key={code} md={6} xs={12}>
									<div className={classes.radioContainer}>
										<Radio
											style={{ fontFamily: 'Commissioner' }}
											className={classes.radioLabel}
											name={name}
											value={code}
											label={name}
											checked={code === selectedType}
											onChange={() => handleChange(code)}
										/>
									</div>
									<Paragraph>
										{code === SINGLE_TYPE
											? t('domain:model-settings.single.description')
											: t('domain:model-settings.probabilistic.description')}
									</Paragraph>
								</Grid>
							);
						})}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ModelRunTypeSwitch;
