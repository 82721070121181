/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Typography } from '@material-ui/core';
import { DataSet } from '../../../../components/Graphs/types';
import i18n from '../../../../config/i18n/configuration';
import { climateFutureColors } from '../../../../theming/climateFutureColors';

export const calculateTopDistance = (width: number, visible?: boolean) => {
	if (visible) {
		return '160px';
	}
	return width > 768 ? '118px' : '108px';
};

export const getAllPanelsTopDistance = (isSettingContainerOpen: boolean) => {
	if (isSettingContainerOpen) {
		return 4000;
	}
	return 3000;
};

export const getProbabilisticDataSet = (
	dataArray: any[],
	emissionScenarioCode: string,
	methods: string[],
	index: number
): DataSet[] => {
	return [
		{
			backgroundColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic
					.background,
			borderColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic
					.background,
			data: dataArray[methods.indexOf('percentile_95')],
			fill: '+1',
			pointRadius: 0,
		},
		{
			backgroundColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic
					.background,
			borderColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic.line,
			label: emissionScenarioCode,
			data: dataArray[methods.indexOf('mean')],
			fill: '+1',
		},
		{
			borderColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic
					.background,
			backgroundColor:
				climateFutureColors[getIndexOrMaxAllowed(index)].probabilistic
					.background,
			data: dataArray[methods.indexOf('percentile_5')],
			fill: false,
			pointRadius: 0,
		},
	];
};

export const convertDataIntoDropdownFormat = (climateVariables: any[]) => {
	return climateVariables?.map((variable: any) => {
		return {
			key: variable.uuid,
			text: i18n.t(
				`domain:climate-futures.variables.${variable.apiCode}.label`
			),
			value: i18n.t(
				`domain:climate-futures.variables.${variable.apiCode}.value`
			),
			content: (
				<>
					<Typography variant="h3">
						{i18n.t(
							`domain:climate-futures.variables.${variable.apiCode}.label`
						)}
					</Typography>
					<p style={{ fontWeight: 400 }}>
						{i18n.t(
							`domain:climate-futures.variables.${variable.apiCode}.description`
						)}
					</p>
				</>
			),
		};
	});
};

export const getIndexOrMaxAllowed = (index: number) => {
	if (index < 9) {
		return index;
	}
	return 9;
};
