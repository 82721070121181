/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode } from 'graphql';
import {
	ApolloError,
	LazyQueryHookOptions,
	OperationVariables,
	QueryTuple,
	useLazyQuery,
} from '@apollo/client';
import useApolloErrorHandling from '../useApolloErrorHandling';

export interface UseLazyQueryOptions<TVariables> extends LazyQueryHookOptions {
	onError?: (error: ApolloError) => void;
	variables?: TVariables;
}

export default <TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	apolloOptions?: UseLazyQueryOptions<TVariables>
): QueryTuple<TData, TVariables> => {
	const [handleError] = useApolloErrorHandling();
	const { onError, ...rest } = apolloOptions || {};

	return useLazyQuery<TData, TVariables>(query, {
		...rest,
		onError: (error: ApolloError) => {
			handleError(error);
			if (onError) {
				onError(error);
			}
		},
	});
};
