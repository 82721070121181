import { gql } from 'apollo-boost';

const runHistory = gql`
	query runHistory($sessionUuid: String!) {
		runHistory(sessionUuid: $sessionUuid) {
			uuid
			hash
			modelRuns {
				uuid
				name
				isActive
				status
				emissionScenario {
					uuid
					name
				}
				modelRunType
				modelParameterSet
				modelParameters {
					name
					value
				}
				createdAt
			}
		}
	}
`;

export default {
	runHistory,
};
