import {
	ApolloError,
	DocumentNode,
	OperationVariables,
	QueryHookOptions,
	QueryResult,
	useQuery,
} from '@apollo/client';
import useApolloErrorHandling from '../useApolloErrorHandling';

export interface UseQueryOptions<TData, TVariables> extends QueryHookOptions {
	onError?: (error: ApolloError) => void;
	variables?: TVariables;
	onCompleted?: (data: TData) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	apolloOptions?: UseQueryOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
	const [onErrorDefault] = useApolloErrorHandling();
	const { onError, variables, fetchPolicy, onCompleted } = apolloOptions || {};

	return useQuery<TData, TVariables>(query, {
		variables,
		fetchPolicy,
		onCompleted,
		onError: (error: ApolloError) => {
			onErrorDefault(error);
			if (onError) {
				onError(error);
			}
		},
	});
};
