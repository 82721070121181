import React, { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from '../domain/Home/Home';

const AppRouter: FC = () => {
	return (
		<BrowserRouter>
			<Route path="/" component={Home} />
		</BrowserRouter>
	);
};

export default AppRouter;
